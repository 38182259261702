import './bundleSave.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
// import { HttpService } from "../../../services/http/http-service";
// import { DataService } from "../../../services/shared-services/dataServices";

import FormControl from "react-bootstrap/FormControl";
import { Card, Modal, Label, Row, Col, Button, CardGroup, CardDeck } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
// import ShoppingCart from "../../ShoppingCart/shopping-cart";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import PackageDetails from "./../../Package-catagory/Holidays-type/package-details/package-details";

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';



import ProgressLoader from "../../shared/progress-loading/progress-loader";
import JwPagination from 'jw-react-pagination';
import { HttpService } from '../../../services/http/http-service';
import ShoppingCart from '../../ShoppingCart/shopping-cart';
import { DataService } from '../../../services/shared-services/dataServices';
import BreadCrumb from '../../shared/breadcrumb/breadcrumb';
// #8238	Mobile - package availability overlaps
// #8239	Mobile - make the button size 		
// #8267 start date
// #9228 Bundle and save Page
class BundleSave extends Component {
    constructor(props) {
        super(props);
        let search = queryString.parse(this.props.location.search);
        this.onChangePage = this.onChangePage.bind(this);

        // store example items and current page of items in local state

        let searchRequestObj = JSON.parse(localStorage.getItem("searchRequestObj"));
        let hotelSearchRequest = JSON.parse(localStorage.getItem("hotelSearchRequest"));

        let items = [
            {
                excludes: [], includes: [], itinerariesList: [],

                package: {
                    description: "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
                    destination: "ADD",
                    destinationId: 1,
                    hotelRate: 0,
                    id: 57,
                    imagePath: "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
                    isChildAllowed: "Yes",
                    isHotelIncluded: false,
                    name: "Debre zeit Crater Lakes",
                    numberOfDays: 1,
                    origin: null,
                    packageBookingValidDateFrom: "2020-06-17T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    status: 0,
                },

                price: {
                    amount: 635.3771,
                    childRate: 50,
                    currency: {
                        code: "AED"
                    },
                    description: null,
                    id: 0,
                    name: null,
                    status: 0,
                    currencyID: 7,
                    description: null,
                    id: 399,
                    max: 1,
                    min: 1,
                    name: null,
                    status: 0,
                }
            },
            {
                excludes: [], includes: [], itinerariesList: [],

                package: {
                    description: "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
                    destination: "ADD",
                    destinationId: 1,
                    hotelRate: 0,
                    id: 59,
                    imagePath: "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
                    isChildAllowed: "Yes",
                    isHotelIncluded: false,
                    name: "Debre zeit Crater Lakes",
                    numberOfDays: 1,
                    origin: null,
                    packageBookingValidDateFrom: "2020-06-17T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    status: 0,
                },

                price: {
                    amount: 635.3771,
                    childRate: 50,
                    currency: {
                        code: "AED"
                    },
                    description: null,
                    id: 0,
                    name: null,
                    status: 0,
                    currencyID: 7,
                    description: null,
                    id: 399,
                    max: 1,
                    min: 1,
                    name: null,
                    status: 0,
                }
            },
            {
                excludes: [], includes: [], itinerariesList: [],

                package: {
                    description: "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
                    destination: "ADD",
                    destinationId: 1,
                    hotelRate: 0,
                    id: 59,
                    imagePath: "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
                    isChildAllowed: "Yes",
                    isHotelIncluded: false,
                    name: "Debre zeit Crater Lakes",
                    numberOfDays: 1,
                    origin: null,
                    packageBookingValidDateFrom: "2020-06-17T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    status: 0,
                },

                price: {
                    amount: 635.3771,
                    childRate: 50,
                    currency: {
                        code: "AED"
                    },
                    description: null,
                    id: 0,
                    name: null,
                    status: 0,
                    currencyID: 7,
                    description: null,
                    id: 399,
                    max: 1,
                    min: 1,
                    name: null,
                    status: 0,
                }
            },
            {
                excludes: [], includes: [], itinerariesList: [],

                package: {
                    description: "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
                    destination: "ADD",
                    destinationId: 1,
                    hotelRate: 0,
                    id: 59,
                    imagePath: "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
                    isChildAllowed: "Yes",
                    isHotelIncluded: false,
                    name: "Debre zeit Crater Lakes",
                    numberOfDays: 1,
                    origin: null,
                    packageBookingValidDateFrom: "2020-06-17T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    status: 0,
                },

                price: {
                    amount: 635.3771,
                    childRate: 50,
                    currency: {
                        code: "AED"
                    },
                    description: null,
                    id: 0,
                    name: null,
                    status: 0,
                    currencyID: 7,
                    description: null,
                    id: 399,
                    max: 1,
                    min: 1,
                    name: null,
                    status: 0,
                }
            },
            {
                excludes: [], includes: [], itinerariesList: [],
                package: {
                    description: "A town lying south east of Addis Ababa with an elevation of 1,920 meters, Debrezeyit is a great day away or overnight excursion located about 45 kilometers southeast of Addis Ababa. The town lies within a circle of six beautiful crater lakes. The temperate climate makes it a very popular resort. Scattered around the lakes are a number of magnificent resorts providing comfortable and relaxing accommodation for visitors. ",
                    destination: "ADD",
                    destinationId: 1,
                    hotelRate: 0,
                    id: 51,
                    imagePath: "https://packagebuilderprod.azurewebsites.net/Images/Packages/d0f899ea-3a8f-4e03-8663-4e14280d07c1_1920x603-pix-screen----ethioholiday--0620.jpg",
                    isChildAllowed: "Yes",
                    isHotelIncluded: false,
                    name: "Debre zeit Crater Lakes",
                    numberOfDays: 1,
                    origin: null,
                    packageBookingValidDateFrom: "2020-06-17T00:00:00",
                    packageBookingValidDateTo: "2020-12-31T00:00:00",
                    status: 0,
                },

                price: {
                    amount: 635.3771,
                    childRate: 50,
                    currency: {
                        code: "AED"
                    },
                    description: null,
                    id: 0,
                    name: null,
                    status: 0,
                    currencyID: 7,
                    description: null,
                    id: 399,
                    max: 1,
                    min: 1,
                    name: null,
                    status: 0,
                }
            },
        ];

        this.state = {
            searchRequestObj: searchRequestObj,
            hotelSearchRequest: hotelSearchRequest,
            initialPackageRequest: { Routes: [{ "from": "today" }] },
            pageOfItems: [],
            bundleAndSaves: [],
            date: null,
            minDate: null,
            NoOfNights: null,
            showModal: false,
            selectedPackage: {},
            selectedPackageSet: [],
            selectedPackageIndex: 0,
            continueLoading: false,
            showModalData: {},
            MultiplePackageSet: [{ packagesList: items }],
            MultiplePackageSetIndex: 0,

            isLoaded: false,
            search: search
        }


        this.handleChange = this.handleChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);

    }

    handleChange(date) {
        this.setState({
            date: date
        })
    }

    onFormSubmit(e) {
        e.preventDefault();
        // console.log(this.state.date)
    }

    onChangePage(pageOfItems) {

        window.scrollTo({ top: 50, behavior: "smooth" });
        this.setState({ pageOfItems });
    }

    async componentDidMount() {

        localStorage.removeItem("PackageSummery");

        this.getAvailableBundles();
    }

    getAvailableBundles() {
        this.setState({
            isLoaded: false,
        });
        var search = this.state.searchRequestObj;
        let sessionId = localStorage.getItem("sessionId");
        let Currency = localStorage.getItem("Currency");
        let apiRelativePath = "/BundleAndSave/Get?DestinationCode=" + search.itineraryParts[0].to.code + "&CurrencyCode=" + Currency;

        HttpService.getServicePackage(apiRelativePath)
            .then((response) => {
                console.log(response);
                let responseData = response.data;
                if (responseData) {
                    this.setState({
                        bundleAndSaves: responseData.bundleAndSaves,
                        isLoaded: true,
                    })

                    // console.log("MultiplePackageSet");
                    // console.log(this.state.MultiplePackageSet);
                }
                else {
                    this.setState({
                        MultiplePackageSet: [],
                        isLoaded: true,
                    });
                    // console.log("MultiplePackageSet");
                    // console.log(this.state.MultiplePackageSet);

                }
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });

    }



    dateOpen = () => {
        const ExampleCustomInput = ({ value, onClick }) => (
            <>
                <FormControl
                    value={(value === "") ? " Start Date* " : value}
                    name="dateValue"
                    id="dateValue"
                    readOnly
                    className="trip"
                    required
                    placeholderText=" Departure Date* "
                    aria-label="DepartureAirport"
                    aria-describedby="DepartureAirport"
                    onClick={onClick}
                />
            </>);
        return (

            <DatePicker
                disabledKeyboardNavigation

                id="date"
                selected={(this.state.date === null) ? (new Date(this.state.searchRequestObj.itineraryParts[this.state.MultiplePackageSetIndex].when.date)) : (this.state.date)}
                onChange={(value) => {
                    this.setState({
                        date: value
                    })
                }}
                name="startDate"
                customInput={<ExampleCustomInput />}
                dateFormat="MM/dd/yyyy"
                minDate={new Date(this.state.searchRequestObj.itineraryParts[this.state.MultiplePackageSetIndex].when.date)}
            />

        )

    }

    addToCart(item) {
        this.setState({ continueLoading: true });
        let sessionId = localStorage.getItem("sessionId");
        if (sessionId) {
            // let apiRelativePath = "/Availability/MultiPackagesSelect";
            let apiRelativePath = "/BundleAndSave/Add";
            let select = {
                "SessionId": sessionId,
                "Ids": [item.id], 
                "BookingDate": document.getElementById("dateValue").value
            }
            HttpService.postServicePackageBooking(select, apiRelativePath)
                .then((response) => {

                    this.setState({ continueLoading: false });
                    let responseData = response.data;
                    if (responseData.statusAPI == "SUCCESS" || responseData.isOkResponse == true) {
                        localStorage.setItem(
                            "BundleSummery",
                            JSON.stringify(responseData.bundleAndSaves)
                        );
                        this.props.history.push("/passenger");
                    }
                    else {
                        // console.log("unsuccesful selection of package");
                    }
                })
                .catch((error) => {
                    // console.log("responseData error2 ");
                });
        }
        else {
            // console.log(" no session error");
            return;
        }

    }

    scrollView(place) {
        let elmnt = document.getElementById(place);
        elmnt.scrollIntoView();
    }


    render() {
        var { isLoaded, bundleAndSaves, } = this.state;


        if (!isLoaded) {
            return <ProgressLoader displayMess="Loading Activities"></ProgressLoader>

        }
        else {
            return (
              <>
                <BreadCrumb pageIndex={2} />
                <div className="m-0 p-0">
                  <div className="row m-0 p-0">
                    <div className="col-12 col-md-3 order-md-2 m-0">
                      <ShoppingCart id={"flight-" + this.props.flightIndex} />
                    </div>
                    <div className="col-12 col-md-9 order-md-1 m-0">
                      <div
                        className=" container pt-0 mt-2"
                        id="initial"
                        style={{ width: "80%" }}
                      >
                        <Card.Header
                          style={{ textAlign: "left" }}
                          className="d-flex p-0 pl-3 m-2 mb-3 success"
                          style={{}}
                          variant="success"
                        >
                          <h5>
                            Available Activities In{" "}
                            {
                              this.state.hotelSearchRequest.params.Destination
                                .value
                            }
                          </h5>
                        </Card.Header>
                        {bundleAndSaves.length < 1 && (
                          <>
                            <Card className="text-center pt-2 pb-0 shadow bg-white rounded">
                              <Card.Header
                                style={{ textAlign: "left" }}
                                className="d-flex p-0 pl-3 success"
                                style={{}}
                                variant="success"
                              >
                                <h5>No activities found!</h5>{" "}
                              </Card.Header>
                              <Card.Body
                                style={{ textAlign: "left" }}
                                id="demo"
                                className=""
                              >
                                <div className="container">
                                  <br />
                                  <h6 className="payment-status-msg ">
                                    <p>
                                      {" "}
                                      No activities found in{" "}
                                      {
                                        this.state.hotelSearchRequest.params
                                          .Destination.value
                                      }{" "}
                                      on specified date!!{" "}
                                    </p>
                                  </h6>
                                </div>
                              </Card.Body>
                            </Card>
                            <br />
                            <Row className="pl-5">
                              <Col style={{ textAlign: "left" }} sm={12}>
                                <Link to={"/"}>
                                  <button
                                    type="button"
                                    className="btn btn-success btn-sm"
                                  >
                                    <strong> Back Home</strong>
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                          </>
                        )}
                        {bundleAndSaves.length >= 1 && (
                          <>
                            {this.state.pageOfItems.map((item) => (
                              <>
                                <Card
                                  className="text-center rounded2  pt-0 pb-0 shadow bg-white"
                                  style={{
                                    borderColor:
                                      this.state.selectedPackage === item.id
                                        ? "green"
                                        : "white",
                                  }}
                                >
                                  <Card.Body className="text-center  mb-0 p-0 ">
                                    <Row>
                                      <Col sm={4}>
                                        <div class="">
                                          <div
                                            className="card-img rounded2"
                                            style={{
                                              cursor: "pointer !important",
                                              backgroundImage:
                                                "url(" + item.imagePath + ")",
                                              maxHeight: "150px",
                                            }}
                                          ></div>
                                        </div>
                                      </Col>
                                      <Col sm={5}>
                                        <Card.Body
                                          className="pt-3 pr-3 pl-2"
                                          style={{ textAlign: "left" }}
                                        >
                                          <Card.Title>{item.name}</Card.Title>
                                          {/* <Card.Subtitle className="mb-2 shorttitle">{item.name}</Card.Subtitle> */}
                                          <Card.Text className="shortDesc">
                                            {item.description}
                                          </Card.Text>
                                          {/* <Card.Link onClick={() => this.setState({ showModal: true, showModalData: item })} style={{ color: "green" }}>View More</Card.Link> */}
                                          <Card.Link>
                                            {this.state.selectedPackage !==
                                              item.id && (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.setState({
                                                    selectedPackage: item.id,
                                                  })
                                                }
                                                class="btn  btn-outline-success btn-sm float-right"
                                              >
                                                Select
                                                <span className="">
                                                  {" "}
                                                  <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faCaretDown}
                                                  />
                                                </span>
                                              </button>
                                            )}
                                            {this.state.selectedPackage ===
                                              item.id && (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  this.setState({
                                                    selectedPackage: null,
                                                  })
                                                }
                                                class="btn btn-outline-success rounded2 btn-sm float-right"
                                              >
                                                <span className="">
                                                  {" "}
                                                  <FontAwesomeIcon
                                                    size="lg"
                                                    icon={faCaretUp}
                                                  />
                                                </span>
                                              </button>
                                            )}
                                          </Card.Link>
                                        </Card.Body>
                                      </Col>
                                      <Col
                                        className="p-3 pl-1"
                                        style={{
                                          borderLeft: "1px solid green",
                                        }}
                                        sm={3}
                                      >
                                        <div
                                          className="row d-flex pl-3"
                                          style={{ textAlign: "left" }}
                                        >
                                          <div className="col-6 col-md-12 col-sm-12">
                                            <Card.Subtitle className="mb-2 text-muted">
                                              Price :{" "}
                                            </Card.Subtitle>
                                          </div>
                                          <div className="col-6 col-md-12 col-sm-12">
                                            <Card.Subtitle className="mb-2">
                                              <strong>
                                                {item.amount}{" "}
                                                {item.currency.code}
                                              </strong>
                                            </Card.Subtitle>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                  {this.state.selectedPackage === item.id && (
                                    <Card.Footer
                                      className=" text-center mt-1 rounded2"
                                      style={{ borderTop: "0.5px solid green" }}
                                    >
                                      <Row>
                                        <Col sm={7}>
                                          <Row>
                                            <Col sm={12}>
                                              <div
                                                className="row d-flex pl-1"
                                                style={{ textAlign: "left" }}
                                              >
                                                <div class="col-7 col-lg-6 col-sm-9">
                                                  <Card.Subtitle className="m-2">
                                                    {" "}
                                                    Number of adult:{" "}
                                                    <strong>
                                                      {" "}
                                                      {
                                                        this.state
                                                          .searchRequestObj
                                                          .passengers.ADT
                                                      }
                                                    </strong>{" "}
                                                  </Card.Subtitle>
                                                  <Card.Subtitle className="m-2">
                                                    {" "}
                                                    Number of Child:{" "}
                                                    <strong>
                                                      {" "}
                                                      {
                                                        this.state
                                                          .searchRequestObj
                                                          .passengers.CHD
                                                      }
                                                    </strong>{" "}
                                                  </Card.Subtitle>
                                                </div>
                                                <div class="col-5 col-lg-6  col-sm-7">
                                                  <Card.Subtitle className="m-2">
                                                    {" "}
                                                    Total Price :
                                                    <strong>
                                                      {" "}
                                                      {item.amount}{" "}
                                                      {item.currency.code}
                                                      {/* {" "} {item.amount * this.state.searchRequestObj.passengers.ADT} {item.currency.code} */}
                                                    </strong>
                                                  </Card.Subtitle>
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col sm={5}>
                                          <div className="row text-center">
                                            <div
                                              class="col-12 col-lg-6  col-sm-12 "
                                              style={{ textAlign: "left" }}
                                            >
                                              <form
                                                onSubmit={this.onFormSubmit}
                                              >
                                                <div className="form-group">
                                                  <this.dateOpen />
                                                  {/* 
                                                                        <DatePicker
                                                                            disabledKeyboardNavigation
                                                                            inline
                                                                            id="date"
                                                                            selected={(this.state.date === null) ? (new Date(this.state.searchRequestObj.itineraryParts[MultiplePackageSetIndex].when.date)) : (this.state.date)}
                                                                            onChange={(value) => {
                                                                                this.setState({
                                                                                    date: value
                                                                                })
                                                                            }}
                                                                            name="startDate"
                                                                            dateFormat="MM/dd/yyyy"
                                                                            minDate={new Date(this.state.searchRequestObj.itineraryParts[MultiplePackageSetIndex].when.date)}
                                                                        /> */}
                                                </div>
                                              </form>
                                            </div>
                                            <div
                                              class="col-12 col-lg-6  col-sm-12"
                                              style={{ textAlign: "center" }}
                                            >
                                              {/* <button type="button" class="btn btn-success rounded2" onClick={() => this.addToCart(item)}><strong className=''>Continue</strong></button> */}

                                              {this.state.date && (
                                                <button
                                                  type="button"
                                                  class="btn btn-success rounded2"
                                                  onClick={() =>
                                                    this.addToCart(item)
                                                  }
                                                >
                                                  <strong className="">
                                                    Continue
                                                  </strong>
                                                </button>
                                              )}
                                              {!this.state.date && (
                                                <button
                                                  type="button"
                                                  class="btn btn-success rounded2"
                                                  disabled
                                                >
                                                  <strong className="">
                                                    Continue
                                                  </strong>
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Footer>
                                  )}
                                </Card>
                                <br />
                              </>
                            ))}
                          </>
                        )}

                        <JwPagination
                          pageSize={4}
                          items={bundleAndSaves}
                          onChangePage={this.onChangePage}
                        />
                        {this.state.continueLoading && (
                          <ProgressLoader displayMess="Selecting Activity"></ProgressLoader>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Modal
                    scrollable={true}
                    size="xl"
                    aria-labelledby="example-custom-modal-styling-title"
                    show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                    <Modal.Header closeButton className="p-1 pl-3 pt-0">
                        <Modal.Title >Package Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PackageDetails packageDetails={this.state.showModalData} />
                    </Modal.Body>
                </Modal> */}
              </>
            );
        }

    }
}

export default BundleSave;