import { HttpService } from "../../services/http/http-service";

class HotelBooking {
  bookHotel(passengerList, props) {
    
    let sessionId = localStorage.getItem("sessionId");
    if (sessionId === null) {
      props.history.push("/");
    }
    let relativePath = "/Booking/Booking";

    let rooms = [];
    let bookingRequest = {
      Holdername: null,
      Holdersurname: null,
      rooms: [],
      clientReference: "Ethiopian",
      SessionId: sessionId,
      remark: "Booking remarks are to be written here.",
      tolerance: 2,
      Amount: null,
    };
    bookingRequest.Amount = parseFloat(
      JSON.parse(localStorage.getItem("hotelTotalPrice"))
    );
    let hotelSearchRequest = JSON.parse(
      localStorage.getItem("hotelSearchRequest")
    );
    let roomRate = JSON.parse(localStorage.getItem("roomRate"));

    let selectedHotelRooms = hotelSearchRequest.params.Rooms;
    for (
      let roomIndex = 0;
      roomIndex < selectedHotelRooms.length;
      roomIndex++
    ) {
      let paxes = [];
      for (
        let adultIndex = 0;
        adultIndex < selectedHotelRooms[roomIndex].params.Adults;
        adultIndex++
      ) {
        paxes.push({
          roomId: 1,
          type: "AD",
          name: passengerList.adultList[adultIndex].firstName,
          surname: passengerList.adultList[adultIndex].lastName,
        });
      }
      for (
        let childIndex = 0;
        childIndex < selectedHotelRooms[roomIndex].params.Children.length;
        childIndex++
      ) {
        paxes.push({
          roomId: 1,
          type: "CH",
          name: passengerList.childList[childIndex].firstName,
          surname: passengerList.childList[childIndex].lastName,
        });
      }
      rooms.push({
        rateKey: roomRate[roomIndex].selectedRateKey,
        rateType: roomRate[roomIndex].selectedRateType,
        rateCommentId: roomRate[roomIndex].selectedRateCommentId,
        paxes: paxes,
      });
    }
    bookingRequest.rooms = rooms;
    bookingRequest.Holdername = passengerList.adultList[0].firstName;
    bookingRequest.Holdersurname = passengerList.adultList[0].lastName;
    this.HotelBooking(bookingRequest, relativePath);
  }
  async HotelBooking(bookingRequest, relativePath) {
    await HttpService.postServiceHotel(bookingRequest, relativePath).then(
      (response) => {
        localStorage.setItem("hotelDetails", JSON.stringify(response));

        
      },
      (error) => {
        
      }
    );
  }
}

export default HotelBooking;
