import React, { Component } from "react";
import "./flight.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlane } from "@fortawesome/free-solid-svg-icons";
import { HttpService } from "./../../services/http/http-service";
import FareOptions from "./fare-options";
import FlightInfoLargeScreen from "./flight-result-lg-screen/flight-info-lg";
import FlightSearchException from "../shared/exception/search-exception";
import FlightSearch from "../../models/flight/flight/flight-search";
import Itinerary from "../../models/flight/flight/itinerary";
import Passenger from "../../models/flight/flight/passenger";
import FlightGenericException from "../shared/exception/generic-exception";
import FlightNotFoundException from "../shared/exception/no-schedule-found-exception";
import FlightSelectionException from "../shared/exception/select-exception";
import ProgressLoader from "../shared/progress-loading/progress-loader";
import FlightHeader from "./flight-header";
import MulticityFlightInformation from "./flight-multicity/multicity-flight-info";
import MulticityFlightInformationMob from "./flight-multicity/multicity-flightinfo-mob";
import BreadCrumb from "../shared/breadcrumb/breadcrumb";
import ShoppingCart from "../ShoppingCart/shopping-cart";
//12612 flight select #12383
class FlightInformation extends Component {
  relativePath = "/Availability/Search";
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      flightInformationList: [],
      flightalternateDay: [],
      flightSearchContextResult: [],
      showFareOptions: false,
      flightInfo: null,
      flightIndex: 0,
      sessionId: null,
      screenWidth: null,
      exception: null,
      result: null,
      firstFlight: false,
    };
    this.searchFlightContext = this.searchFlightContext.bind(this);
    this.passenger = new Passenger();
    this.itinerary = new Itinerary();
    this.itineraryTwo = new Itinerary();
    this.itineraryList = [];
    this.flightSearch = new FlightSearch();
  }
  async componentDidMount() {
    localStorage.removeItem("PackageSummery");
    localStorage.removeItem("hotelfinalsummary");
    localStorage.removeItem("passengerDetails");
    localStorage.removeItem("summaryDetails");
    localStorage.removeItem("hotelDetails");
    window.scrollTo({ top: 0, behavior: "smooth" });
    await this.setState({ screenWidth: window.innerWidth });
    // this.passenger.params.ADT = 1;
    // this.passenger.params.CHD = 1;
    // this.passenger.params.INF = 1;
    // this.itinerary.params.from.code = "ADD";
    // this.itinerary.params.to.code = "DXB";
    // this.itinerary.params.when.date = "2020-12-10";
    // this.itineraryTwo.params.from.code = "DXB";
    // this.itineraryTwo.params.to.code = "ADD";
    // this.itineraryTwo.params.when.date = "2020-12-20";
    // this.itineraryList.push(this.itinerary.params);
    // // this.itineraryList.push(this.itineraryTwo.params);


    let searchRequestObjectFromLS = null;
    
    let test = localStorage.getItem("searchRequestObj");
    if (
      localStorage.getItem("searchRequestObj") != null &&
      localStorage.getItem("searchRequestObj") != "undefined"
    ) {
      searchRequestObjectFromLS = JSON.parse(
        localStorage.getItem("searchRequestObj")
      );
    }

    if (searchRequestObjectFromLS === null) {
      this.props.history.push({
        pathname: "/",
      });
    } else {

     this.initSearchParam()
      // this.flightSearch.params.cabinClass =  searchRequestObjectFromLS.cabinClass;
      // this.flightSearch.params.itineraryParts =  searchRequestObjectFromLS.itineraryParts;
      // this.flightSearch.params.passengers = searchRequestObjectFromLS.passengers;

      if (this.props.contextSearchParam != null) {
        this.setState({
          flightIndex: this.props.contextSearchParam.flightIndex,
        });
      }
      if (this.state.flightIndex === 0) {
        this.searchFlight("this.flightSearch.params");
      }
    }
  }

  initSearchParam(){
    
   let searchRequestObjectFromLS=null;
   searchRequestObjectFromLS = JSON.parse(
      localStorage.getItem("searchRequestObj")
    );

    if (searchRequestObjectFromLS != null) {

    this.flightSearch.params.cabinClass =  searchRequestObjectFromLS.cabinClass;
    this.flightSearch.params.itineraryParts =  searchRequestObjectFromLS.itineraryParts;
    this.flightSearch.params.passengers = searchRequestObjectFromLS.passengers;   
    }
  }

  showFlightException() {
    if (this.state.exception === "search") {
      return (
        <>
          <FlightSearchException />
        </>
      );
    }
    if (this.state.exception === "schedule") {
      return (
        <>
          <FlightNotFoundException />
        </>
      );
    }
    if (this.state.exception === "select") {
      return (
        <>
          <FlightSelectionException />
        </>
      );
    }
    if (this.state.exception === "generic") {
      return (
        <>
          <FlightGenericException />
        </>
      );
    }

    return <></>;
  }

  searchFlight(params) { 
    
    this.setState({ isLoading: true });

    let prop = this.props.location.props;
    
    if (prop == undefined) {
      localStorage.setItem("totalFlighAmount", 0);
      localStorage.setItem("flightFare", 0);
      localStorage.setItem("flightTax", 0);
      localStorage.setItem("hotelTotalPrice", 0);
      localStorage.setItem("surcharges", 0);
      localStorage.setItem("Currency", "");
      localStorage.setItem("flightInfo", null);
      
      localStorage.removeItem("flightalternateDay")
      HttpService.postService(this.flightSearch.params, "/Availability/Search")
        .then((response) => { 
          
          let responseData = response.data;
          this.setState({ isLoading: false });
           
              localStorage.setItem(
                "flightalternateDay",
                JSON.stringify(response.data.single.alternateDayResponse)
              );

              this.setState({ flightalternateDay: response.data.single.alternateDayResponse });

          this.setFlightResult(responseData);
          HttpService.getFlightInformation()
            .then((response) => { 
              localStorage.setItem(
                "FlightInformation",
                JSON.stringify(response.data.fareCompares)
              );
            })
            .catch((error) => {
              // console.log(error);
            });
        })
        .catch((error) => {
          
          // console.log(error);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setFlightResult(this.props.location.props.result);
      this.setState({ isLoading: false });
    }
  }

  setFlightResult(flightResult) {
    try {
      
      let responseData = flightResult;
      //let searchType = this.props.location.props.type; 
     localStorage.setItem(
        "flightalternateDay",
        JSON.stringify(responseData.single.alternateDayResponse)
      ); 
    
      localStorage.setItem(
        "flightdata",
        JSON.stringify(responseData)
      );
// console.log("flightIndex",this.state.flightIndex)
// console.log("responseData.single.alternateDayResponse",responseData.single.alternateDayResponse)

      this.setState({ flightalternateDay: responseData.single.alternateDayResponse });

      if (responseData.single.statusAPI === "ERROR") {
        this.setState({ exception: "search" });
      } else if (
        responseData.single.statusAPI === "SUCCESS" &&
        responseData.single.searchResponse.brandedResults.itineraryPartBrands[0]
          .length === 0
      ) {
        this.setState({ exception: "schedule" });
      } else {
        this.setState({ exception: null });
        const {
          itineraryPartBrands,
        } = responseData.single.searchResponse.brandedResults;
        this.setState({ flightInformationList: itineraryPartBrands[0] });

        this.setState({
          sessionId: responseData.single.cookieSabreDataRequest.sessionId,
        });
        localStorage.setItem(
          "sessionId",
          responseData.single.cookieSabreDataRequest.sessionId
        );
      }
    } catch (ex) {
      
      this.setState({ exception: "schedule" });
    }
  }

  setHotelCheckInDate(date) {
    
    let tempMonth = (new Date(date).getMonth() + 1);
    if(tempMonth <10){
      tempMonth = "0"+tempMonth;
    }
    let arrivalDate =
      new Date(date).getFullYear() +
      "-" +tempMonth
       +
      "-" +
      new Date(date).getDate();
    let hotelSearchRequest = JSON.parse(
      localStorage.getItem("hotelSearchRequest")
    );
    

    if (!this.state.firstFlight) {
      this.setState({ firstFlight: true });
      if(localStorage.getItem("selectedPreference")!="flightPlusPackage"){
        hotelSearchRequest.params.CheckIn = arrivalDate;
        localStorage.setItem(
          "hotelSearchRequest",
          JSON.stringify(hotelSearchRequest)
        );
      }

    }
  }
  async updateFlightIndex() {
    let newFlightIndex = this.state.flightIndex;
    newFlightIndex++;
    await this.setState({ flightIndex: newFlightIndex });
  }
  async updateFlightIndexDown() {
    let newFlightIndex = this.state.flightIndex;
    newFlightIndex--;
    await this.setState({ flightIndex: newFlightIndex });
  }

  async searchFlightContext(shoppingCode, arrivalDate, departDate) {
    localStorage.setItem("flightIndex",this.state.flightIndex);
    let AlternativeFlightsDayClicked = await JSON.parse(localStorage.getItem("AlternativeFlightsDayClicked"));
    if(AlternativeFlightsDayClicked){
      await this.updateFlightIndexDown();
      this.initSearchParam();
    }
  // 1 searchFlightContext page 
    this.relativePath = "/Availability/SearchContext";

    let selectedPreference = localStorage.getItem("tourPreference");
    if (
      selectedOfferRefCode != null &&
      selectedOfferRefCode != "flightPlusPackage"
    ) {
      this.setHotelCheckInDate(arrivalDate);
    }
    this.hideFareOptions();
    let isMulticity = localStorage.getItem("isMulticity");

    if (isMulticity == "yes") {
      this.flightSearch.params.itineraryParts[
        this.state.flightIndex
      ].selectedOfferRef = shoppingCode;
      this.selectFlight();
    } 
    else {
      if (
        this.state.flightIndex + 1 ===
        this.flightSearch.params.itineraryParts.length
      ) {
        if (this.flightSearch.params.itineraryParts.length === 1) {
          this.flightSearch.params.itineraryParts[
            this.state.flightIndex
          ].selectedOfferRef = shoppingCode;
        } else {
          let i = 0;
          for (i; i < this.state.flightIndex; i++) {
            var selectedOfferRefCode = this.state.flightSearchContextResult[
              i
            ][0].brandOffers[0].shoppingBasketHashCode;
            this.flightSearch.params.itineraryParts[
              i
            ].selectedOfferRef = selectedOfferRefCode;
          }
          this.flightSearch.params.itineraryParts[
            this.state.flightIndex
          ].selectedOfferRef = shoppingCode;
        }
        
        this.selectFlight();
      } 
      else {
        this.setState({ isLoading: true });
        this.flightSearch.params.cookieSabreDataRequest = {
          sessionid: this.state.sessionId,
        };
        this.flightSearch.params.FinalIndex = 2;

        if (this.state.flightIndex === 0) {
          for (
            let i = 0;
            i < this.flightSearch.params.itineraryParts.length;
            i++
          ) {
            this.flightSearch.params.itineraryParts[i].selectedOfferRef = null;
          }
          this.flightSearch.params.itineraryParts[0].selectedOfferRef = shoppingCode;
        } else {
          let i = 0;
          for (i; i < this.state.flightIndex; i++) {
            let selectedOfferRefCode = this.state.flightInformationList[i][0]
              .brandOffers[0].shoppingBasketHashCode;
            this.flightSearch.params.itineraryParts[
              i
            ].selectedOfferRef = selectedOfferRefCode;
          }
          this.flightSearch.params.itineraryParts[
            this.state.flightIndex
          ].selectedOfferRef = shoppingCode;
        }
      }
      if(this.relativePath !== "/Availability/SelectFlight"){
        HttpService.postService(this.flightSearch.params, this.relativePath)
        .then((response) => {
          this.setState({ isLoading: false });
          
          let responseData = response.data;
          
          // console.log("searchFlightContext response index",this.state.flightIndex, responseData)
          localStorage.setItem(
            "flightalternateDay",
            JSON.stringify(response.data.single.alternateDayResponse)
          );

          this.setState({ flightalternateDay: response.data.single.alternateDayResponse });

          if (responseData.single.statusAPI === "ERROR") {
            this.setState({ exception: "search" });
          } else if (
            responseData.single.statusAPI === "SUCCESS" &&
            responseData.single.searchResponse.brandedResults
              .itineraryPartBrands[0].length === 0
          ) {
            this.setState({ exception: "schedule" });
          } else {
            this.setState({ exception: null });
            const {
              itineraryPartBrands,
            } = responseData.single.searchResponse.brandedResults;
            this.setState({ flightInformationList: itineraryPartBrands[1] });
            this.setState({ flightSearchContextResult: itineraryPartBrands });
          }
        })
        .catch((error) => {
          
          this.setState({ exception: "generic" });
        });
      }

    }
    await this.updateFlightIndex();
  }

  async selectFlight() {
    this.relativePath = "/Availability/SelectFlight";

    this.flightSearch.params.cookieSabreDataRequest = {
      sessionid: this.state.sessionId,
    };
    HttpService.postService(this.flightSearch.params, this.relativePath)
      .then((response) => {
        
        let responseData = response.data;

        // console.log("selectFlight response index",this.state.flightIndex, responseData)
        if (responseData.statusAPI === "ERROR") {
          this.setState({ exception: "select" });
          this.setState({ isLoading: false });
        } else {
          // localStorage.setItem("flightTax", flightTax);
          // localStorage.setItem("surcharges", 0);
          
          var tempVariable = response.data;
          localStorage.setItem(
            "totalFlighAmount",
            tempVariable.total.alternatives[0][0].amount
          );
          localStorage.setItem(
            "Currency",
            tempVariable.total.alternatives[0][0].currency
          );
          
          var localFlightInfo = JSON.parse(localStorage.getItem("flightInfo"));
          if(localFlightInfo != null){
            localFlightInfo.forEach((flightInfo)=>{
              tempVariable.productBreakdowns.forEach((element) => {
                
                if (element.label == "air") {
                  element.breakdownElements.forEach((ele) => {
                    if (ele.label == "SURCHARGES") {
                      if(flightInfo.surcharges == null){
                        flightInfo.surcharges =ele.price.alternatives[0][0].amount;
                      }
                    }
    
                  });
                }
              });  
         
            })
             }

             localStorage.setItem("flightInfo",JSON.stringify(localFlightInfo));
             let selectedPreference = localStorage.getItem("selectedPreference");
          if (selectedPreference != null) {
            if (selectedPreference == "hotel"||selectedPreference == "bundle") {
              localStorage.setItem("tourPreference", "flightPlusHotel");
              this.props.history.push({
                pathname: "/hotel",
              });
            }
            if (
              selectedPreference == "package" ||
              selectedPreference === "multiCity"
            ) {
              localStorage.setItem("tourPreference", "flightPlusPackage");
              this.props.history.push({
                pathname: "/packages",
              });
            }
          } else {
            this.props.history.push({
              pathname: "/",
            });
          }
        }
      })
      .catch((error) => {
        this.setState({ exception: "generic" });
        this.setState({ isLoading: false });
      });
      this.setState({ isLoading: true });

  }
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  async showFareOptions(flightInformation) {
    await this.setState({ itineraryParts: flightInformation });
    await this.setState({ showFareOptions: true });
  }
  async hideFareOptions() {
    await this.setState({ showFareOptions: false });
  }
  renderFareOptions = () => {
    return (
      <div>

        <div className="flight-options-header">

          <h5 className="text-center mt-3 d-inline-block">Select Fare Option</h5>
          <button
            className="btn float-right mt-2 mr-1"
            onClick={() => this.hideFareOptions()}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ color: "white", fontSize: "17px" }}
            />
          </button>
        </div>
        <FareOptions
          state={this.state.itineraryParts}
          onSearchContextClicked={this.searchFlightContext}
        />
      </div>
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <ProgressLoader />
        </div>
      );
    }




    if (this.state.exception != null) {
      return <>{this.showFlightException()}</>;
    }
    if (this.state.showFareOptions && this.state.screenWidth <= 860) {
      return <>{this.renderFareOptions()}</>;
    }
    
    // this.state.screenWidth > 860
    if (this.state.screenWidth > 860) {
      
      let isMulticity = localStorage.getItem("isMulticity");
      if (isMulticity == "yes") {
        return (
          <> 
          <BreadCrumb pageIndex={1}/>
            {" "}   
            <MulticityFlightInformation
              flightalternateDay={this.state.flightalternateDay}
              flightInfo={this.state.flightInformationList}
              onSearchContextClicked={this.searchFlightContext}
              flightIndex={this.state.flightIndex}
            />
          </>
        );
      }
      return (
        <>
        <BreadCrumb pageIndex={1}/>
          {" "}
           
          <FlightInfoLargeScreen 
            flightalternateDay={this.state.flightalternateDay}
            flightInfo={this.state.flightInformationList}
            onSearchContextClicked={this.searchFlightContext}
            flightIndex={this.state.flightIndex}
          />
        </>
      );
    }
    // this.state.screenWidth > 860


    // this.state.screenWidth <= 860
    if (this.state.screenWidth <= 860) {
      let isMulticity = localStorage.getItem("isMulticity");
      if (isMulticity == "yes") {
        return (
          <>
            {" "} 
            <MulticityFlightInformationMob
              flightalternateDay={this.state.flightalternateDay}
              flightInfo={this.state.flightInformationList}
              onSearchContextClickedMob={this.searchFlightContext}
              flightIndex={this.state.flightIndex}
            />
          </>
        );
      }
      return (
        <> 
        <div className="mobile-widget">
          <div className="mt-4 mb-4">
            {/* <h5 className="text-center">Select Departure flight</h5> */}
            <FlightHeader 
            flightIndex={this.state.flightIndex}  
          flightalternateDay={this.state.flightalternateDay}
          onSearchContextClickedHeader={this.searchFlightContext}
          />

          
    
          </div>

          {this.state.flightInformationList.map((flightInfo, key) => (
            <div
              className="flight-info"
              onClick={() => this.showFareOptions(flightInfo)}
              key={key}
              title="Click to select"
            >
              <div className="flight-route">
                {/* <div className="d-inline float-left fly-origin">DXB</div> */}
                <div className="d-inline fly-duration">
                  {this.parseTime(flightInfo.duration)}
                </div>
                {/* <div className="d-inline float-right fly-destination">ADD</div> */}
              </div>
              <div className="flight-duration">
                <div className="d-inline float-left fly-depart-time">
                  {this.parseDate(flightInfo.departure)}
                </div>
                <div className="d-inline fly-duration">
                  <p className="path-left">
                    {" "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      style={{ color: "#adb5bd", fontSize: "18px" }}
                    />{" "}
                    <span className="flight-separtor-dot"></span>
                  </p>
                </div>
                <div className="d-inline float-right fly-arrival-time">
                  {this.parseDate(flightInfo.arrival)}
                </div>
              </div>
              {flightInfo.echeepPrice !="N/A" && <div className="flight-price-info">
                <div className="d-inline float-right fly-arrival-time">
                  
                  <span className="pr-2 from-txt">from </span>
                  <span className="price">{flightInfo.echeepPrice}</span>
                  
                <button  className="btn  submit-fare-option-btn ml-2" size="small">select</button>
                </div>
              </div>}
            </div>
          ))}
        </div>
      
        </>
        );
    }
    // this.state.screenWidth <= 860
    return <></>;
  }
}

export default FlightInformation;
