import React, { Component } from "react";
import "./top-destinations.css";
import { HttpService } from "../../services/http/http-service";

class TopDestinations extends Component {
  constructor(props) {
    super(props);

      if (JSON.parse(localStorage.getItem("Destinations"))) {
          let localitem = JSON.parse(localStorage.getItem("Destinations"));
      this.state = {
        items: localitem,
        isLoaded: true,
      };
    } else {
      this.state = {
        items: [],
        isLoaded: false,
      };
    }
  }

  async componentDidMount() {
    let catagory;
    if (this.props.match.params.catagory == "Holidays Type") {
      catagory = "Holiday Type";
    } else {
      catagory = "Special Offers";
    }

      if (JSON.parse(localStorage.getItem("Destinations"))) {
    } else {
      this.getDestinations();
    }
  }

  getDestinations() {
    const relativePath = "/MasterDatas/GetMasterDatas?SearchType=CONTINENT";
    HttpService.getServicePackage(relativePath)
      .then((response) => {
        let responseData = response.data;
        localStorage.setItem(
          "Destinations",
          JSON.stringify(responseData.continents)
        );
        this.setState({
          items: responseData.continents,
          isLoaded: true,
        });
      })
      .catch((error) => {
        // console.log("responseData error2 ");
      });
  }

  showPackageLists = (a) => {
    //  this.router.navigate(['/package-details']);
    let path = "/package-lists/" + a;
    window.location.assign(path);
  };

  render() {
    var { isLoaded, items } = this.state;
    if (!isLoaded) {
      return <div>loading top-destinations...{isLoaded}</div>;
    } else {
      return (
        <div className="special-offer container pt-0">
          <h1 className="text-center special-offer-head">top-destinations</h1>

          <div class="row row-cols-1 row-cols-md-2">
            {items.map((item) => (
              <div class="col-lg-4 col-md-6 mb-4" key={item} value={item}>
                <div class="card">
                  <div
                    className="card-img"
                    style={{
                      backgroundImage:
                        "url(" + item.countries[0].imagePath + ")",
                    }}
                  ></div>
                  <div class="card-body">
                    <h5 class="card-title">{item.name}</h5>
                    <button
                      type="button"
                      className="btn et-btn"
                      onClick={() => this.showPackageLists(item.id)}
                    >
                      {" "}
                      MORE{" "}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
}

export default TopDestinations;
