import React, { Component } from "react";
import "./widget.css";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import DateRangeIcon from "@material-ui/icons/DateRange";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ChildrenIput from "./ChildrenIput";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Redirect } from "react-router-dom";
import { HttpService } from "../../../services/http/http-service";

import ProgressLoader from "../../shared/progress-loading/progress-loader";
import { Room } from "../../../models/hotel/room";
import { HotelSearch } from "../../../models/hotel/hotel-search";
import Select from "react-select";
import { AirportList } from "../../shared/master-data/airport-list/airport-list";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isBrowser, isMobile } from "react-device-detect";
import { DataService } from "../../../services/shared-services/dataServices";
import moment from "moment";
import {
  faSync
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BundleAndSave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Room: {
        TotalNumberOfRoom: 1,
        TotalNumberOfAdult: 1,
        TotalNumberOfChildren: 0,
        TotalNumberOfInfant: 0,
        RoomLabel: "Room",

        RoomText: "1 Adult ,0 Child (1 room)",
        RoomOne: {
          NumberOfAdult: 1,
          NumberOfChildren: 0,
          NumberOfInfant: 0,
          AdultText: "Adult",
          ChildText: "Child",
          Children: [],
        },

        RoomTwo: {
          showRoom: false,
          NumberOfAdult: 0,
          NumberOfChildren: 0,
          NumberOfInfant: 0,
          AdultText: "Adult",
          ChildText: "Child",
          Children: [],
        },
        RoomThree: {
          showRoom: false,
          NumberOfAdult: 0,
          NumberOfChildren: 0,
          NumberOfInfant: 0,
          AdultText: "Adult",
          ChildText: "Child",
          Children: [],
        },

        RoomFour: {
          showRoom: false,
          NumberOfAdult: 0,
          NumberOfChildren: 0,
          NumberOfInfant: 0,
          AdultText: "Adult",
          ChildText: "Child",
          Children: [],
        },
      },

      from: "",
      to: "",
      class: 3,
      startDate: null,
      endDate: null,
      airportlist: [],
      bodyParameters: {
        cabinClass: 3,
        awardBooking: false,
        searchType: 1,
        promoCodes: null,
        itineraryParts: [],
        passengers: {
          ADT: 1,
          CHD: 0,
          INF: 0,
        },
      },
      responseFromServer: null,
      isLoading: false,
      isFromValid: false,
      isToValid: false,
      showDialog: false,
      showPassengerDialog: false,
      valid_date: "valid_date",
      messageAPI: null,
      networkError: false,
      networkErrorMessage: false,
    };
    this.hotelSearch = new HotelSearch();
    this.addChildren = this.addChildren.bind(this);
  }


  addChildren = (index) => {
    if (index == 1) {
      const length = this.state.Room.RoomOne.Children.length;
      if (
        this.state.Room.TotalNumberOfAdult +
          this.state.Room.TotalNumberOfChildren <
          9 &&
        length < 4
      ) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren + 1;
        tempstate.RoomOne.NumberOfChildren =
          tempstate.RoomOne.NumberOfChildren + 1;
        tempstate.RoomOne.Children.push(ChildrenIput);
        if (length + 1 >= 2) {
          tempstate.RoomOne.ChildText = "Children";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else if (index == 2) {
      const length = this.state.Room.RoomTwo.Children.length;
      if (
        this.state.Room.TotalNumberOfAdult +
          this.state.Room.TotalNumberOfChildren <
          9 &&
        length < 4
      ) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren + 1;
        tempstate.RoomTwo.NumberOfChildren =
          tempstate.RoomTwo.NumberOfChildren + 1;
        tempstate.RoomTwo.Children.push(ChildrenIput);
        if (length + 1 >= 2) {
          tempstate.RoomTwo.ChildText = "Children";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else if (index == 3) {
      const length = this.state.Room.RoomThree.Children.length;
      if (
        this.state.Room.TotalNumberOfAdult +
          this.state.Room.TotalNumberOfChildren <
          9 &&
        length < 4
      ) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren + 1;
        tempstate.RoomThree.NumberOfChildren =
          tempstate.RoomThree.NumberOfChildren + 1;
        tempstate.RoomThree.Children.push(ChildrenIput);
        if (length + 1 >= 2) {
          tempstate.RoomThree.ChildText = "Children";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else {
      const length = this.state.Room.RoomFour.Children.length;
      if (
        this.state.Room.TotalNumberOfAdult +
          this.state.Room.TotalNumberOfChildren <
          9 &&
        length < 4
      ) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren + 1;
        tempstate.RoomFour.NumberOfChildren =
          tempstate.RoomFour.NumberOfChildren + 1;
        tempstate.RoomFour.Children.push(ChildrenIput);
        if (length + 1 >= 2) {
          tempstate.RoomFour.ChildText = "Children";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    }
  };
  removeChildren = (index) => {
    if (index == 1) {
      const length = this.state.Room.RoomOne.Children.length;
      if (length >= 0 && this.state.Room.RoomOne.NumberOfChildren != 0) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren - 1;
        tempstate.RoomOne.NumberOfChildren =
          tempstate.RoomOne.NumberOfChildren - 1;
        let popped = tempstate.RoomOne.Children.pop();
        if (length - 1 >= 2) {
          tempstate.RoomOne.ChildText = "Children";
        } else {
          tempstate.RoomOne.ChildText = "Child";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else if (index == 2) {
      const length = this.state.Room.RoomTwo.Children.length;
      if (length >= 0 && this.state.Room.RoomTwo.NumberOfChildren != 0) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren - 1;
        tempstate.RoomTwo.NumberOfChildren =
          tempstate.RoomTwo.NumberOfChildren - 1;
        let popped = tempstate.RoomTwo.Children.pop();
        if (length + 1 >= 2) {
          tempstate.RoomTwo.ChildText = "Children";
        } else {
          tempstate.RoomTwo.ChildText = "Child";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else if (index == 3) {
      const length = this.state.Room.RoomThree.Children.length;
      if (length >= 0 && this.state.Room.RoomThree.NumberOfChildren != 0) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren - 1;
        tempstate.RoomThree.NumberOfChildren =
          tempstate.RoomThree.NumberOfChildren - 1;
        let popped = tempstate.RoomThree.Children.pop();
        if (length + 1 >= 2) {
          tempstate.RoomThree.ChildText = "Children";
        } else {
          tempstate.RoomThree.ChildText = "Child";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    } else {
      const length = this.state.Room.RoomFour.Children.length;
      if (length >= 0 && this.state.Room.RoomFour.NumberOfChildren != 0) {
        let tempstate = this.state.Room;
        tempstate.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren - 1;
        tempstate.RoomFour.NumberOfChildren =
          tempstate.RoomFour.NumberOfChildren - 1;
        let popped = tempstate.RoomFour.Children.pop();
        if (length + 1 >= 2) {
          tempstate.RoomFour.ChildText = "Children";
        } else {
          tempstate.RoomFour.ChildText = "Child";
        }
        if (this.state.Room.TotalNumberOfChildren >= 2) {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Children (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        } else {
          tempstate.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " rooms)";
        }
        this.setState({ Room: tempstate });
      }
    }
  };
  roomPlusButton = (event) => {
    event.preventDefault();
    let tempState = this.state.Room;
    if (
      this.state.Room.TotalNumberOfRoom + 1 <= 4 &&
      this.state.Room.TotalNumberOfAdult +
        this.state.Room.TotalNumberOfChildren <
        9
    ) {
      if (this.state.Room.TotalNumberOfRoom + 1 == 2) {
        tempState.RoomTwo.showRoom = true;
        tempState.RoomTwo.NumberOfAdult = 1;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        tempState.RoomLabel = "Rooms";
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " rooms)";
        this.setState({ Room: tempState });
      } else if (this.state.Room.TotalNumberOfRoom + 1 == 3) {
        tempState.RoomThree.showRoom = true;
        tempState.RoomThree.NumberOfAdult = 1;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " rooms)";
        this.setState({ Room: tempState });
      } else if (this.state.Room.TotalNumberOfRoom + 1 == 4) {
        tempState.RoomFour.showRoom = true;
        tempState.RoomFour.NumberOfAdult = 1;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " rooms)";
        this.setState({ Room: tempState });
      } else {
      }
    }
  };
  roomMinusButton = (event) => {
    event.preventDefault();
    let tempState = this.state.Room;
    if (this.state.Room.TotalNumberOfRoom != 1) {
      if (this.state.Room.RoomFour.showRoom) {
        // let tempState = this.state.Room;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom - 1;
        tempState.TotalNumberOfAdult =
          this.state.Room.TotalNumberOfAdult -
          this.state.Room.RoomFour.NumberOfAdult;
        tempState.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren -
          this.state.Room.RoomFour.NumberOfChildren;
        tempState.TotalNumberOfInfant =
          this.state.Room.TotalNumberOfInfant -
          this.state.Room.RoomFour.NumberOfInfant;
        tempState.RoomFour.NumberOfAdult = 0;
        tempState.RoomFour.NumberOfChildren = 0;
        tempState.RoomFour.NumberOfInfant = 0;
        tempState.RoomFour.showRoom = false;

        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child ( " +
          this.state.Room.TotalNumberOfRoom +
          " " +
          this.state.Room.RoomLabel +
          " )";
        this.setState({ Room: tempState });
      } else if (this.state.Room.RoomThree.showRoom) {
        // let tempState = this.state.Room;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom - 1;
        tempState.TotalNumberOfAdult =
          this.state.Room.TotalNumberOfAdult -
          this.state.Room.RoomThree.NumberOfAdult;
        tempState.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren -
          this.state.Room.RoomThree.NumberOfChildren;
        tempState.TotalNumberOfInfant =
          this.state.Room.TotalNumberOfInfant -
          this.state.Room.RoomThree.NumberOfInfant;
        tempState.RoomThree.NumberOfAdult = 0;
        tempState.RoomThree.NumberOfChildren = 0;
        tempState.RoomThree.NumberOfInfant = 0;
        tempState.RoomThree.showRoom = false;

        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child ( " +
          this.state.Room.TotalNumberOfRoom +
          " " +
          this.state.Room.RoomLabel +
          " )";
        this.setState({ Room: tempState });
      } else if (this.state.Room.RoomTwo.showRoom) {
        // let tempState = this.state.Room;
        tempState.TotalNumberOfRoom = this.state.Room.TotalNumberOfRoom - 1;
        tempState.TotalNumberOfAdult =
          this.state.Room.TotalNumberOfAdult -
          this.state.Room.RoomTwo.NumberOfAdult;
        tempState.TotalNumberOfChildren =
          this.state.Room.TotalNumberOfChildren -
          this.state.Room.RoomTwo.NumberOfChildren;
        tempState.TotalNumberOfInfant =
          this.state.Room.TotalNumberOfInfant -
          this.state.Room.RoomTwo.NumberOfInfant;
        tempState.RoomTwo.NumberOfAdult = 0;
        tempState.RoomTwo.NumberOfChildren = 0;
        tempState.RoomTwo.NumberOfInfant = 0;
        tempState.RoomTwo.showRoom = false;
        tempState.RoomLabel = "Room";
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child ( " +
          this.state.Room.TotalNumberOfRoom +
          " " +
          this.state.Room.RoomLabel +
          " )";
        this.setState({ Room: tempState });
      }
    }
  };
  AdultPlusButton = (index) => {
    let tempState = this.state.Room;

    if (
      this.state.Room.TotalNumberOfAdult +
        this.state.Room.TotalNumberOfChildren <
      9
    ) {
      if (index == 1) {
        tempState.RoomOne.AdultText = "Adults";
        tempState.RoomOne.NumberOfAdult =
          this.state.Room.RoomOne.NumberOfAdult + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        if (tempState.TotalNumberOfAdult >= 2) {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        } else {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adult " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        }

        this.setState({ Room: tempState });
      } else if (index == 2) {
        tempState.RoomTwo.AdultText = "Adults";
        tempState.RoomTwo.NumberOfAdult =
          this.state.Room.RoomTwo.NumberOfAdult + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        if (tempState.TotalNumberOfAdult >= 2) {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        } else {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adult " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        }
        this.setState({ Room: tempState });
      } else if (index == 3) {
        tempState.RoomThree.AdultText = "Adults";
        tempState.RoomThree.NumberOfAdult =
          this.state.Room.RoomThree.NumberOfAdult + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        if (tempState.TotalNumberOfAdult >= 2) {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        } else {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adult " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        }
        this.setState({ Room: tempState });
      } else {
        tempState.RoomFour.AdultText = "Adults";
        tempState.RoomFour.NumberOfAdult =
          this.state.Room.RoomFour.NumberOfAdult + 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult + 1;
        if (tempState.TotalNumberOfAdult >= 2) {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adults " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        } else {
          tempState.RoomText =
            this.state.Room.TotalNumberOfAdult +
            " Adult " +
            this.state.Room.TotalNumberOfChildren +
            " Child (" +
            this.state.Room.TotalNumberOfRoom +
            " room)";
        }
        this.setState({ Room: tempState });
      }
    }
  };
  AdultMinusButton = (index) => {
    let tempState = this.state.Room;

    if (index == 1) {
      if (tempState.RoomOne.NumberOfAdult > 1) {
        if (tempState.RoomOne.NumberOfAdult == 2) {
          tempState.RoomOne.AdultText = "Adult";
          if (tempState.TotalNumberOfAdult == 1) {
            tempState.RoomText =
              this.state.Room.TotalNumberOfAdult +
              " Adult " +
              this.state.Room.TotalNumberOfChildren +
              " Child (" +
              this.state.Room.TotalNumberOfRoom +
              " room)";
          }
        }
        tempState.RoomOne.NumberOfAdult =
          this.state.Room.RoomOne.NumberOfAdult - 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult - 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " room)";
        this.setState({ Room: tempState });
      }
    } else if (index == 2) {
      if (tempState.RoomTwo.NumberOfAdult > 1) {
        if (tempState.RoomTwo.NumberOfAdult == 2) {
          tempState.RoomTwo.AdultText = "Adult";
          if (tempState.TotalNumberOfAdult == 1) {
            tempState.RoomText =
              this.state.Room.TotalNumberOfAdult +
              " Adult " +
              this.state.Room.TotalNumberOfChildren +
              " Child (" +
              this.state.Room.TotalNumberOfRoom +
              " room)";
          }
        }
        tempState.RoomTwo.NumberOfAdult =
          this.state.Room.RoomTwo.NumberOfAdult - 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult - 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " room)";
        this.setState({ Room: tempState });
      }
    } else if (index == 3) {
      if (tempState.RoomThree.NumberOfAdult > 1) {
        if (tempState.RoomThree.NumberOfAdult == 2) {
          tempState.RoomThree.AdultText = "Adult";
          if (tempState.TotalNumberOfAdult == 1) {
            tempState.RoomText =
              this.state.Room.TotalNumberOfAdult +
              " Adult " +
              this.state.Room.TotalNumberOfChildren +
              " Child (" +
              this.state.Room.TotalNumberOfRoom +
              " room)";
          }
        }
        tempState.RoomThree.NumberOfAdult =
          this.state.Room.RoomThree.NumberOfAdult - 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult - 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " room)";
        this.setState({ Room: tempState });
      }
    } else {
      if (tempState.RoomFour.NumberOfAdult > 1) {
        if (tempState.RoomFour.NumberOfAdult == 2) {
          tempState.RoomFour.AdultText = "Adult";
          if (tempState.TotalNumberOfAdult == 1) {
            tempState.RoomText =
              this.state.Room.TotalNumberOfAdult +
              " Adult " +
              this.state.Room.TotalNumberOfChildren +
              " Child (" +
              this.state.Room.TotalNumberOfRoom +
              " room)";
          }
        }
        tempState.RoomFour.NumberOfAdult =
          this.state.Room.RoomFour.NumberOfAdult - 1;
        tempState.TotalNumberOfAdult = this.state.Room.TotalNumberOfAdult - 1;
        tempState.RoomText =
          this.state.Room.TotalNumberOfAdult +
          " Adults " +
          this.state.Room.TotalNumberOfChildren +
          " Child (" +
          this.state.Room.TotalNumberOfRoom +
          " room)";
        this.setState({ Room: tempState });
      }
    }
  };
  ChildPlusButton = (index) => {};
  InfantPlusButton = (index) => {
    let tempState = this.state.Room;
    if (this.state.Room.TotalNumberOfInfant < 3) {
      if (index == 1) {
        if (tempState.RoomOne.NumberOfInfant == 0) {
          tempState.RoomOne.NumberOfInfant = 1;
          tempState.TotalNumberOfInfant =
            this.state.Room.TotalNumberOfInfant + 1;
          this.setState({ Room: tempState });
        }
      } else if (index == 2) {
        if (tempState.RoomTwo.NumberOfInfant == 0) {
          tempState.RoomTwo.NumberOfInfant = 1;
          tempState.TotalNumberOfInfant =
            this.state.Room.TotalNumberOfInfant + 1;
          this.setState({ Room: tempState });
        }
      } else if (index == 3) {
        if (tempState.RoomThree.NumberOfInfant == 0) {
          tempState.RoomThree.NumberOfInfant = 1;
          tempState.TotalNumberOfInfant =
            this.state.Room.TotalNumberOfInfant + 1;
          this.setState({ Room: tempState });
        }
      } else {
        if (tempState.RoomFour.NumberOfInfant == 0) {
          tempState.RoomFour.NumberOfInfant = 1;
          tempState.TotalNumberOfInfant =
            this.state.Room.TotalNumberOfInfant + 1;
          this.setState({ Room: tempState });
        }
      }
    }
  };
  InfantMinusButton = (index) => {
    let tempState = this.state.Room;

    if (index == 1) {
      if (tempState.RoomOne.NumberOfInfant == 1) {
        tempState.RoomOne.NumberOfInfant = 0;
        tempState.TotalNumberOfInfant = this.state.Room.TotalNumberOfInfant - 1;
        this.setState({ Room: tempState });
      }
    } else if (index == 2) {
      if (tempState.RoomTwo.NumberOfInfant == 1) {
        tempState.RoomTwo.NumberOfInfant = 0;
        tempState.TotalNumberOfInfant = this.state.Room.TotalNumberOfInfant - 1;
        this.setState({ Room: tempState });
      }
    } else if (index == 3) {
      if (tempState.RoomThree.NumberOfInfant == 1) {
        tempState.RoomThree.NumberOfInfant = 0;
        tempState.TotalNumberOfInfant = this.state.Room.TotalNumberOfInfant - 1;
        this.setState({ Room: tempState });
      }
    } else {
      if (tempState.RoomFour.NumberOfInfant == 1) {
        tempState.RoomFour.NumberOfInfant = 0;
        tempState.TotalNumberOfInfant = this.state.Room.TotalNumberOfInfant - 1;
        this.setState({ Room: tempState });
      }
    }
  };
  toDictionaryList = (props) => {
    let tempobj = [];
    props.forEach((value, index) => {
      tempobj.push({ id: index, value: value });
    });
    return tempobj;
  };
  search = (event) => {
    event.preventDefault();
    
    let defaultSearchParameter = this.state.bodyParameters;
    defaultSearchParameter.cabinClass = 3;
    defaultSearchParameter.searchType = 1;
    defaultSearchParameter.itineraryParts = [];
    defaultSearchParameter.passengers.ADT = 1;
    defaultSearchParameter.passengers.CHD = 0;
    defaultSearchParameter.passengers.INF = 0;
    this.setState({ bodyParameters: defaultSearchParameter });
    if(this.props.searchFlightAgain =="true"){
      document.getElementsByClassName("modal-backdrop")[1].style.display = "none";
      document.getElementsByClassName("modal-dialog")[1].style.display = "none";
    }

    else{
      document.getElementsByClassName("modal-backdrop")[0].style.display = "none";
      document.getElementsByClassName("modal-dialog")[0].style.display = "none";
    }
    if (
      this.state.from == "" ||
      this.state.to == "" ||
      this.state.startDate == null ||
      this.state.endDate == null
    ) {
      if (this.state.from == "") {
        this.setState({ isFromValid: true });
      }
      if (this.state.to == "") {
        this.setState({ isToValid: true });
      }
      if (this.state.startDate == null || this.state.endDate == null) {
        this.setState({ valid_date: "invalid_date" });
      }
    } else {
      this.setState({ isLoading: true });
      if (this.props.journeyType == "Hotel Check Out") {
        let tempitineraryParts = {
          from: {
            code: this.state.from.value.substring(
              this.state.from.value.indexOf(")") - 3,
              this.state.from.value.indexOf(")")
            ),
          },
          to: {
            code: this.state.to.value.substring(
              this.state.to.value.indexOf(")") - 3,
              this.state.to.value.indexOf(")")
            ),
          },
          when: {
            date: this.state.startDate.format("YYYY-MM-DD"),
          },
        };

        let tempState = this.state.bodyParameters;
        tempState.itineraryParts.push(tempitineraryParts);
        tempState.passengers.ADT = this.state.Room.TotalNumberOfAdult;
        tempState.passengers.CHD = this.state.Room.TotalNumberOfChildren;
        tempState.passengers.INF = this.state.Room.TotalNumberOfInfant;
        tempState.cabinClass = parseInt(this.state.class);
        this.setState({ bodyParameters: tempState });
      } else {
        let tempitineraryParts = {
          from: {
            code: this.state.from.value.substring(
              this.state.from.value.indexOf(")") - 3,
              this.state.from.value.indexOf(")")
            ),
          },
          to: {
            code: this.state.to.value.substring(
              this.state.to.value.indexOf(")") - 3,
              this.state.to.value.indexOf(")")
            ),
          },
          when: {
            date: this.state.startDate.format("YYYY-MM-DD"),
          },
        };

        let tempState = this.state.bodyParameters;
        tempState.itineraryParts.push(tempitineraryParts);
        tempitineraryParts = {
          from: {
            code: this.state.to.value.substring(
              this.state.to.value.indexOf(")") - 3,
              this.state.to.value.indexOf(")")
            ),
          },
          to: {
            code: this.state.from.value.substring(
              this.state.from.value.indexOf(")") - 3,
              this.state.from.value.indexOf(")")
            ),
          },
          when: {
            date: this.state.endDate.format("YYYY-MM-DD"),
          },
        };
        tempState.itineraryParts.push(tempitineraryParts);

        tempState.passengers.ADT = this.state.Room.TotalNumberOfAdult;
        tempState.passengers.CHD = this.state.Room.TotalNumberOfChildren;
        tempState.passengers.INF = this.state.Room.TotalNumberOfInfant;
        tempState.cabinClass = parseInt(this.state.class);

        this.setState({ bodyParameters: tempState });
      }
      let room = new Room();
      room.params.Rooms = 1;
      room.params.Adults = this.state.Room.RoomOne.NumberOfAdult;
      if (this.state.Room.RoomOne.NumberOfChildren >= 1) {
        room.params.Children.push(
          parseInt(document.getElementById("Children-0-Room-1").value)
        );
        if (this.state.Room.RoomOne.NumberOfChildren >= 2) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-1-Room-1").value)
          );
        }
        if (this.state.Room.RoomOne.NumberOfChildren >= 3) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-2-Room-1").value)
          );
        }
        if (this.state.Room.RoomOne.NumberOfChildren == 4) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-3-Room-1").value)
          );
        }
      }
      this.hotelSearch.params.Rooms.push(room);
      if (this.state.Room.RoomTwo.showRoom) {
        let room = new Room();
        room.params.Rooms = 1;
        room.params.Adults = this.state.Room.RoomTwo.NumberOfAdult;
        if (this.state.Room.RoomTwo.NumberOfChildren >= 1) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-0-Room-2").value)
          );
          if (this.state.Room.RoomTwo.NumberOfChildren >= 2) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-1-Room-2").value)
            );
          }
          if (this.state.Room.RoomTwo.NumberOfChildren >= 3) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-2-Room-2").value)
            );
          }
          if (this.state.Room.RoomTwo.NumberOfChildren == 4) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-3-Room-2").value)
            );
          }
        }
        this.hotelSearch.params.Rooms.push(room);
      }
      if (this.state.Room.RoomThree.showRoom) {
        let room = new Room();
        room.params.Rooms = 1;
        room.params.Adults = this.state.Room.RoomThree.NumberOfAdult;
        if (this.state.Room.RoomThree.NumberOfChildren >= 1) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-0-Room-3").value)
          );
          if (this.state.Room.RoomThree.NumberOfChildren >= 2) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-1-Room-3").value)
            );
          }
          if (this.state.Room.RoomThree.NumberOfChildren >= 3) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-2-Room-3").value)
            );
          }
          if (this.state.Room.RoomThree.NumberOfChildren == 4) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-3-Room-3").value)
            );
          }
        }
        this.hotelSearch.params.Rooms.push(room);
      }
      if (this.state.Room.RoomFour.showRoom) {
        let room = new Room();
        room.params.Rooms = 1;
        room.params.Adults = this.state.Room.RoomFour.NumberOfAdult;
        if (this.state.Room.RoomFour.NumberOfChildren >= 1) {
          room.params.Children.push(
            parseInt(document.getElementById("Children-0-Room-4").value)
          );
          if (this.state.Room.RoomFour.NumberOfChildren >= 2) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-1-Room-4").value)
            );
          }
          if (this.state.Room.RoomFour.NumberOfChildren >= 3) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-2-Room-4").value)
            );
          }
          if (this.state.Room.RoomFour.NumberOfChildren == 4) {
            room.params.Children.push(
              parseInt(document.getElementById("Children-3-Room-4").value)
            );
          }
        }
        this.hotelSearch.params.Rooms.push(room);
      }
      this.hotelSearch.params.Destination = this.state.to;
      this.hotelSearch.params.CheckIn = this.state.startDate.format(
        "YYYY-MM-DD"
      );
      this.hotelSearch.params.CheckOut = this.state.endDate.format(
        "YYYY-MM-DD"
      );

      localStorage.setItem(
        "hotelSearchRequest",
        JSON.stringify(this.hotelSearch)
      );
      localStorage.setItem("totalFlighAmount", 0);
      localStorage.setItem("flightFare", 0);
      localStorage.setItem("flightTax", 0);
      localStorage.setItem("hotelTotalPrice", 0);
      localStorage.setItem("surcharges", 0);
      localStorage.setItem("Currency", "");
      localStorage.setItem("flightInfo", null);
      localStorage.setItem("bundAndSave", true);     
       localStorage.setItem("BundleSummery", null);
       localStorage.setItem("isMulticity", "no");
       localStorage.setItem("selectedPreference", "bundle");


       if(this.props.searchFlightAgain =="true"){
        localStorage.setItem(
          "searchRequestObj",
          JSON.stringify(this.state.bodyParameters)
        );
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host;
        window.location=baseUrl +"/flight"
        return true;
      }
      if(this.props.searchFlightAgain =="true"){
        localStorage.setItem(
          "searchRequestObj",
          JSON.stringify(this.state.bodyParameters)
        );
        localStorage.setItem("selectedPreference", "bundle");
        this.props.history.push({
          pathname: "/flight",
        });
        window.location.reload(false);
        return true;
      }
      HttpService.postService(this.state.bodyParameters, "/Availability/Search")
        .then((response) => {
          
          localStorage.setItem(
            "searchRequestObj",
            JSON.stringify(this.state.bodyParameters)
          );
          HttpService.getFlightInformation()
          .then((response) => {
            localStorage.setItem(
              "FlightInformation",
              JSON.stringify(response.data.fareCompares)
            );
            localStorage.setItem("tourPreference","bundleAndSave");
          })
          .catch((error) => {
            console.log(error);
          });
          this.setState({ responseFromServer: response.data });
          this.setState({ isLoading: false });

        })
        .catch((error) => {

            //debugger
          this.setState({
            isLoading: false,
            messageAPI: "YOUR SESSION HAS EXPIRED DUE TO INACTIVITY",
            networkError: true,
            networkErrorMessage:true
          });
          // return (
          //   <Redirect
          //     to={{
          //       pathname: "/error-page",
          //       props: {
          //         statusCode: error.statusCode,
          //         message: error.message,
          //       },
          //     }}
          //   />
          // );
        });
    }
  };
  closeDialog = () => {
    if(this.state.networkErrorMessage){
      localStorage.setItem("holidaysTkn",null);
      window.location.reload(false);
    }
    this.setState({
      showDialog: false,
      messageAPI: null,
    });
  };
  showDialog = () => {
    this.setState({
      responseFromServer: null,
      networkError: false,
      showDialog: true,
    });
  };
  showPassengerDialog = () => {
    let temp = this.state.showPassengerDialog;
    this.setState({ showPassengerDialog: !temp });
  };
  componentWillMount=()=>{
    
    var searchObj= JSON.parse(localStorage.getItem("searchRequestObj"));
    if(this.props.searchFlightAgain =="true"){
      var temp = this.state;
      temp.from ={label:DataService.getFullCityName(searchObj.itineraryParts[0].from.code),value:DataService.getFullCityName(searchObj.itineraryParts[0].from.code)} ;
      temp.to ={label:DataService.getFullCityName(searchObj.itineraryParts[0].to.code),value:DataService.getFullCityName(searchObj.itineraryParts[0].to.code)} ;
      temp.startDate = moment(searchObj.itineraryParts[0].when.date);
      try{
        temp.endDate = moment(searchObj.itineraryParts[1].when.date);
      }catch{
        if(localStorage.getItem("selectedPreference")=="hotel" ||localStorage.getItem("selectedPreference")=="bundle"){
          var hotelSearchRequest =JSON.parse(localStorage.getItem("hotelSearchRequest"))
          temp.endDate = moment(hotelSearchRequest.CheckOut);
        }
      }      this.setState(temp);
  
    }
  }
  render() {
    let romeSelecter = null;
    let roomTwo = null;
    let roomThree = null;
    let roomFour = null;
    let loading = null;
    var numberOfMonth;
    if (isMobile) {
      numberOfMonth = 1;
    }
    if (isBrowser) {
      numberOfMonth = 3;
    }
    const airpotList = AirportList.getAll;
    let error = null;
    if (this.state.isLoading) {
      loading = (
        <div className="Loading-div">
          <div className="Loading">
            <ProgressLoader displayMess="Searching Available Flight" />
          </div>
        </div>
      );
    }
    if (this.state.responseFromServer != null || this.state.networkError) {
      if (this.state.networkError) {
        this.showDialog();
      } else if (this.state.responseFromServer != null) {
        if (this.state.responseFromServer.single.statusAPI === "ERROR") {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else if (
          this.state.responseFromServer.single.statusAPI === "SUCCESS" &&
          this.state.responseFromServer.single.searchResponse.brandedResults
            .itineraryPartBrands[0].length === 0
        ) {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else {
          if (this.state.showOneWay) {
            localStorage.setItem("selectedPreference", "bundle");
            return (
              <Redirect
                to={{
                  pathname: "/flight",
                  props: {
                    result: this.state.responseFromServer,
                    type: "oneWay",
                    category: "hotel",
                    hotelSearch: this.hotelSearch,
                  },
                }}
              />
            );
          } else {
            localStorage.setItem("selectedPreference", "bundle");

            return (
              <Redirect
                to={{
                  pathname: "/flight",
                  props: {
                    result: this.state.responseFromServer,
                    type: "round",
                    category: "hotel",
                    hotelSearch: this.hotelSearch,
                  },
                }}
              />
            );
          }
        }
      }
    }

    let airportListLable = airpotList.map((Element, index) => {
      return (
        <option className="airportlist" value={Element}>
          {Element}
        </option>
      );
    });
    let roomOneChildren = this.state.Room.RoomOne.Children.map(
      (Element, index) => {
        return <Element key={index} index={index} room={1} />;
      }
    );
    let roomTwoChildren = this.state.Room.RoomTwo.Children.map(
      (Element, index) => {
        return <Element key={index} index={index} room={2} />;
      }
    );
    let roomThreeChildren = this.state.Room.RoomThree.Children.map(
      (Element, index) => {
        return <Element key={index} index={index} room={3} />;
      }
    );
    let roomFourChildren = this.state.Room.RoomFour.Children.map(
      (Element, index) => {
        return <Element key={index} index={index} room={4} />;
      }
    );

    let roomOne = (
      <div>
        <div className="row ">
          <h4>Room 1</h4>
        </div>
        <div></div>
        <div className="row">
          <div className="col-6">
            <span>{this.state.Room.RoomOne.AdultText}</span>
          </div>
          <div className="col-6 mb-2">
            <div className="top-circle">
              <button
                className="btn"
                onClick={this.AdultMinusButton.bind(this, "1")}
              >
                <RemoveIcon className="inside-icon-minus" />
              </button>
            </div>
            &nbsp;&nbsp;
            <span> {this.state.Room.RoomOne.NumberOfAdult}</span>
            &nbsp;&nbsp;
            <div className="top-circle">
              <button
                className="btn"
                onClick={this.AdultPlusButton.bind(this, "1")}
              >
                <AddIcon className="inside-icon-plus" />
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>{this.state.Room.RoomOne.ChildText}</span>
          </div>
          <div className="col-6 mb-2">
            <div className="top-circle">
              <button
                className="btn"
                onClick={this.removeChildren.bind(this, 1)}
              >
                <RemoveIcon className="inside-icon-minus" />
              </button>
            </div>
            &nbsp;&nbsp;
            <span> {this.state.Room.RoomOne.NumberOfChildren}</span>
            &nbsp;&nbsp;
            <div className="top-circle">
              <button className="btn" onClick={this.addChildren.bind(this, 1)}>
                <AddIcon className="inside-icon-plus" />
              </button>
            </div>
          </div>
        </div>
        <div className="row">{roomOneChildren}</div>
        <div className="row">
          <div className="col-6">
            <span>Infant</span>
          </div>
          <div className="col-6 mb-2">
            <div className="top-circle">
              <button
                className="btn"
                onClick={this.InfantMinusButton.bind(this, 1)}
              >
                <RemoveIcon className="inside-icon-minus" />
              </button>
            </div>
            &nbsp;&nbsp;
            <span> {this.state.Room.RoomOne.NumberOfInfant}</span>
            &nbsp;&nbsp;
            <div className="top-circle">
              <button
                className="btn"
                onClick={this.InfantPlusButton.bind(this, 1)}
              >
                <AddIcon className="inside-icon-plus" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    if (this.state.Room.RoomTwo.showRoom) {
      roomTwo = (
        <div>
          <hr style={{ border: "1px solid #4caf50" }} />
          <div className="row">
            <h4>Room 2</h4>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomTwo.AdultText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultMinusButton.bind(this, "2")}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomTwo.NumberOfAdult}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultPlusButton.bind(this, "2")}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomTwo.ChildText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.removeChildren.bind(this, 2)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomTwo.NumberOfChildren}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.addChildren.bind(this, 2)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">{roomTwoChildren}</div>
          <div className="row">
            <div className="col-6">
              <span>Infant</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantMinusButton.bind(this, 2)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomTwo.NumberOfInfant}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantPlusButton.bind(this, 2)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.Room.RoomThree.showRoom) {
      roomThree = (
        <div>
          <hr style={{ border: "1px solid #4caf50" }} />
          <div className="row">
            <h4>Room 3</h4>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomThree.AdultText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultMinusButton.bind(this, "3")}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomThree.NumberOfAdult}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultPlusButton.bind(this, "3")}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomThree.ChildText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.removeChildren.bind(this, 3)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomThree.NumberOfChildren}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.addChildren.bind(this, 3)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">{roomThreeChildren}</div>

          <div className="row">
            <div className="col-6">
              <span>Infant</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantMinusButton.bind(this, 3)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              <span> {this.state.Room.RoomThree.NumberOfInfant}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantPlusButton.bind(this, 3)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.Room.RoomFour.showRoom) {
      roomFour = (
        <div>
          <hr style={{ border: "1px solid #4caf50" }} />
          <div className="row">
            <h4>Room 4</h4>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomFour.AdultText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultMinusButton.bind(this, "4")}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              {this.state.Room.RoomFour.NumberOfAdult}
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.AdultPlusButton.bind(this, "4")}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>{this.state.Room.RoomFour.ChildText}</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.removeChildren.bind(this, 4)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              {this.state.Room.RoomFour.NumberOfChildren}
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.addChildren.bind(this, 4)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">{roomFourChildren}</div>

          <div className="row">
            <div className="col-6">
              <span>Infant</span>
            </div>
            <div className="col-6 mb-2">
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantMinusButton.bind(this, 4)}
                >
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              &nbsp;&nbsp;
              {this.state.Room.RoomFour.NumberOfInfant}
              &nbsp;&nbsp;
              <div className="top-circle">
                <button
                  className="btn"
                  onClick={this.InfantPlusButton.bind(this, 4)}
                >
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    let customStylesFrom = {
      option: (provided, state) => ({
        ...provided,
        "text-align": "left",
        color: state.isSelected ? "white" : "black",
        width: state.selectProps.width,
        backgroundColor: state.isSelected ? "#4caf50" : "white",
        "&:hover": {
          backgroundColor: "#4caf50",
          color: "white",
        },
      }),
      control: (base, state) => ({
        ...base,
        "min-height": "50px",
        "min-width": "100% !important",
        "&:active": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
        "&:hover": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
        "&:focus": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
      }),
    };
    let customStylesTo = {
      option: (provided, state) => ({
        ...provided,
        "text-align": "left",
        color: state.isSelected ? "white" : "black",
        width: state.selectProps.width,
        backgroundColor: state.isSelected ? "#4caf50" : "white",
        "&:hover": {
          backgroundColor: "#4caf50",
          color: "white",
        },
      }),
      control: (base, state) => ({
        ...base,
        "min-height": "50px",
        "min-width": "100% !important",
        "&:active": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
        "&:hover": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
        "&:focus": {
          "border-color": "#4caf50 !important",
          "box-shadow": "none !important",
          outline: "none !important",
        },
      }),
    };
    if (this.state.isFromValid) {
      customStylesFrom = {
        option: (provided, state) => ({
          ...provided,
          "text-align": "left",
          color: state.isSelected ? "white" : "black",
          width: state.selectProps.width,
          backgroundColor: state.isSelected ? "#4caf50" : "white",
          "&:hover": {
            backgroundColor: "#4caf50",
            color: "white",
          },
        }),
        control: (base, state) => ({
          ...base,
          "min-height": "50px",
          "min-width": "100% !important",
          "border-color": "#dc3545",
          "background-image":
            " url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' hei…circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e) !important",
          "background-repeat": "no-repeat",
          "background-position": "right calc(.375em + .1875rem) center centerWidget",
          " background-size": "calc(.75em + .375rem) calc(.75em + .375rem)",
          "&:active": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
          "&:hover": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
          "&:focus": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
        }),
      };
    }
    if (this.state.isToValid) {
      customStylesTo = {
        option: (provided, state) => ({
          ...provided,
          "text-align": "left",
          color: state.isSelected ? "white" : "black",
          width: state.selectProps.width,
          backgroundColor: state.isSelected ? "#4caf50" : "white",
          "&:hover": {
            backgroundColor: "#4caf50",
            color: "white",
          },
        }),
        control: (base, state) => ({
          ...base,
          "min-height": "50px",
          "min-width": "100% !important",
          "border-color": "#dc3545",
          "background-image":
            " url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' hei…circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e) !important",
          "background-repeat": "no-repeat",
          "background-position": "right calc(.375em + .1875rem) center centerWidget",
          " background-size": "calc(.75em + .375rem) calc(.75em + .375rem)",
          "&:active": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
          "&:hover": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
          "&:focus": {
            "border-color": "#4caf50 !important",
            "box-shadow": "none !important",
            outline: "none !important",
          },
        }),
      };
    }
    return (
      <div className="tab-content-widget">
        <Modal
          show={this.state.showDialog}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="closingModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div id="wrapper-sc">
                <img
                  class="planeImage-sc"
                  src="https://d22j4fzzszoii2.cloudfront.net/images/1521/2314/EthiopainAirlinesPlane.png"
                  width="633"
                  height="338"
                  alt="Ethiopain Airlines"
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h4> {this.state.messageAPI} </h4>
            {this.state.networkErrorMessage?<p style={{textAlign:"center centerWidget"}}><b>please reload to continue</b>
            <br/>
            <FontAwesomeIcon
              icon={faSync}
              style={{ color: "#60ac5d", fontSize: "4em" }}
              onClick={()=>{
                localStorage.setItem("holidaysTkn",null);
                window.location.reload(false);
              }}
            />
            </p>:<span>Sorry we can't find your flight, please try again or change your search criteria</span>}
          </Modal.Body>
        </Modal>
        {loading}
        <Form onSubmit={this.search} method="Post">
          <Form.Row>
            <Form.Group as={Col}>
              {/* <Form.Label>From*</Form.Label> */}
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {/* <FlightTakeoffSharp /> */}
                    <span className="et-icons departure-flight-icon"></span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Select
                    onFocus={() => this.setState({ isFromValid: false })}
                    value={this.state.from}
                    onChange={(value) => this.setState({ from: value })}
                    options={airpotList}
                    isSearchable={true}
                    styles={customStylesFrom}
                    name="departureAirport"
                    id="flight-hotel-departure-airport"
                    placeholder={
                      <div className="departurAirport">Departure Airport</div>
                    }
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="departureAirport">
                    <span className="et-icons arrival-flight-icon"></span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Select
                    onFocus={() => this.setState({ isToValid: false })}
                    value={this.state.to}
                    onChange={(value) => this.setState({ to: value })}
                    options={airpotList}
                    isSearchable={true}
                    styles={customStylesTo}
                    id="flight-hotel-arravial-airpot"
                    placeholder={<div>Arrival Airport</div>}
                    name="arravialAirpot"
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text className="date-range-icon">
                    <DateRangeIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className={this.state.valid_date}>
                  <DateRangePicker
                    required={true}
                    readOnly={true}
                    noBorder={true}
                    numberOfMonths={numberOfMonth}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="start_date12" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="end_date12" // PropTypes.string.isRequired,
                    startDatePlaceholderText="Depart date"
                    endDatePlaceholderText={this.props.journeyType}
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({ startDate, endDate })
                    } // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput) =>
                      this.setState({ focusedInput, valid_date: "valid_date" })
                    } // PropTypes.func.isRequired,
                  />
                </div>
              </InputGroup>
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              {/* <Form.Label>Travelers + Rooms *</Form.Label> */}
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="TravelersRoom">
                    <span className="et-icons passengers-icon"></span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={this.state.Room.RoomText}
                  name="TravelersRoom"
                  readOnly
                  className="trip"
                  required
                  aria-label="DepartureAirport"
                  aria-describedby="DepartureAirport"
                  onClick={this.showPassengerDialog}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              {/* <Form.Label>&nbsp;&nbsp;</Form.Label> */}
              <InputGroup className="mb-3">
                <select
                  className="form-control select-widget"
                  onChange={(e) => this.setState({ class: e.target.value })}
                >
                  <option defaultValue value={3}>
                    All Class
                  </option>
                  <option value={1}>Economy</option>
                  <option value={2}>Business</option>
                </select>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              {/* <Form.Label>&nbsp;&nbsp;</Form.Label> */}
              <InputGroup className="mb-3   justify-content-center">
                <button
                  className="search-btn btn btn-success"
                  type="submit"
                  onClick={this.showPassengerDialog}
                >
                  Search
                </button>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          {/* <Form.Row>
            <Form.Group as={Col}>
              <InputGroup>{romeSelecter}</InputGroup>
            </Form.Group>
          </Form.Row> */}
        </Form>
        <Modal
          show={this.state.showPassengerDialog}
          onHide={this.showPassengerDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span>{this.state.Room.RoomLabel}</span>
              &nbsp;&nbsp;
              <div className="top-circle">
                <button onClick={this.roomMinusButton} className="btn">
                  <RemoveIcon className="inside-icon-minus" />
                </button>
              </div>
              <span> {this.state.Room.TotalNumberOfRoom} </span>
              <div className="top-circle">
                <button className="btn" onClick={this.roomPlusButton}>
                  <AddIcon className="inside-icon-plus" />
                </button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: "1em" }}>
              {roomOne}
              {roomTwo}
              {roomThree}
              {roomFour}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="search-btn btn btn-success"
              onClick={this.showPassengerDialog}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default BundleAndSave;
