import React, { Component } from "react";
import FlightTakeoffSharp from "@material-ui/icons/FlightTakeoffSharp";
import Clear from "@material-ui/icons/Clear";

import GroupAdd from "@material-ui/icons/GroupAdd";

import "bootstrap/dist/css/bootstrap.min.css";

import "./widget.css";
import Alert from 'react-bootstrap/Alert'
import InputGroup from "react-bootstrap/InputGroup";
import DateRangeIcon from "@material-ui/icons/DateRange";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "react-bootstrap/Button";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Autocomplete from "react-autocomplete";
import { Redirect } from "react-router-dom";
import { HttpService } from "../../../services/http/http-service";
import ProgressLoader from "../../shared/progress-loading/progress-loader";
import DatePicker from "react-datepicker";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { DataService } from "../../../services/shared-services/dataServices";


class MultiCity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValid:true,
      TotalNumberOfAdult: 1,
      TotalNumberOfChildren: 0,
      TotalNumberOfInfant: 0,
      showPassengerSelecter: false,
      AdultText: "Adult",
      ChildText: "Child",
      InfantText: "Infant",
      passengerText: "1 Adult ,0 Child ,0 Infant",
      showRemoveButton: false,
      flight1From: "",
      flight1To: "",
      flight1StartDate: null,
      flight2From: "",
      flight2To: "",
      flight2StartDate: null,
      flight3From: "",
      flight3To: "",
      flight3StartDate: null,
      flight4From: "",
      flight4To: "",
      flight4StartDate: null,
      flight5From: "",
      flight5To: "",
      flight5StartDate: null,
      flight6From: "",
      flight6To: "",
      flight6StartDate: null,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      class: 3,
      focused1: false,
      focused2: false,
      focused3: false,
      focused4: false,
      focused5: false,
      focused6: false,
      bodyParameters: {
        cabinClass: 3,
        awardBooking: false,
        searchType: 1,
        promoCodes: null,
        itineraryParts: [],
        passengers: {
          ADT: 1,
          CHD: 0,
          INF: 0,
        },
      },
      responseFromServer: null,
      showDialog: false,
      messageAPI: null,
      networkError: false,
    };
  }
  componentDidMount = () => {
    $(".react-datepicker__input-container input").attr(
      "placeholder",
      "Departure Date"
    );
    $(".react-datepicker__input-container input").attr("required", "true");
  };
  componentDidUpdate = () => {
    $(".react-datepicker__input-container input").attr(
      "placeholder",
      "Departure Date"
    );
    $(".react-datepicker__input-container input").attr("required", "true");
  };

  search = (event) => {
    this.setState({ formValid: false }); 
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return
    }
    // console.log("form.checkValidity()",form.checkValidity())

    this.setState({ formValid: true }); 

    localStorage.setItem("totalFlighAmount", 0);
    localStorage.setItem("flightFare", 0);
    localStorage.setItem("flightTax", 0);
    localStorage.setItem("hotelTotalPrice", 0);
    localStorage.setItem("surcharges", 0);
    localStorage.setItem("Currency", "");
    event.preventDefault();
    this.setState({ isLoading: true });
    let defaultSearchParameter = this.state.bodyParameters;
    defaultSearchParameter.cabinClass = 3;
    defaultSearchParameter.searchType = 1;
    defaultSearchParameter.itineraryParts = [];
    defaultSearchParameter.passengers.ADT = 1;
    defaultSearchParameter.passengers.CHD = 0;
    defaultSearchParameter.passengers.INF = 0;
    this.setState({ bodyParameters: defaultSearchParameter });
    let tempState = this.state.bodyParameters;
    //flight 1
    let tempitineraryParts = {
      from: {
        code: this.state.flight1From.substring(
          this.state.flight1From.indexOf(")") - 3,
          this.state.flight1From.indexOf(")")
        ),
      },
      to: {
        code: this.state.flight1To.substring(
          this.state.flight1To.indexOf(")") - 3,
          this.state.flight1To.indexOf(")")
        ),
      },
      when: {
        date: moment(this.state.flight1StartDate).format("YYYY-MM-DD"),
      },
    };
    tempState.itineraryParts.push(tempitineraryParts);

    //flight 2
    tempitineraryParts = null;
    tempitineraryParts = {
      from: {
        code: this.state.flight2From.substring(
          this.state.flight2From.indexOf(")") - 3,
          this.state.flight2From.indexOf(")")
        ),
      },
      to: {
        code: this.state.flight2To.substring(
          this.state.flight2To.indexOf(")") - 3,
          this.state.flight2To.indexOf(")")
        ),
      },
      when: {
        date: moment(this.state.flight2StartDate).format("YYYY-MM-DD"),
        // date: this.state.flight2StartDate.format("YYYY-MM-DD"),
      },
    };
    tempState.itineraryParts.push(tempitineraryParts);

    //flight 3
    if (this.state.show3) {
      tempitineraryParts = null;

      tempitineraryParts = {
        from: {
          code: this.state.flight3From.substring(
            this.state.flight3From.indexOf(")") - 3,
            this.state.flight3From.indexOf(")")
          ),
        },
        to: {
          code: this.state.flight3To.substring(
            this.state.flight3To.indexOf(")") - 3,
            this.state.flight3To.indexOf(")")
          ),
        },
        when: {
          date: moment(this.state.flight3StartDate).format("YYYY-MM-DD"),
          // date: this.state.flight3StartDate.format("YYYY-MM-DD"),
        },
      };
      tempState.itineraryParts.push(tempitineraryParts);
    }

    //flight 4
    if (this.state.show4) {
      tempitineraryParts = null;

      tempitineraryParts = {
        from: {
          code: this.state.flight4From.substring(
            this.state.flight4From.indexOf(")") - 3,
            this.state.flight4From.indexOf(")")
          ),
        },
        to: {
          code: this.state.flight4To.substring(
            this.state.flight4To.indexOf(")") - 3,
            this.state.flight4To.indexOf(")")
          ),
        },
        when: {
          date: moment(this.state.flight4StartDate).format("YYYY-MM-DD"),
          // date: this.state.flight4StartDate.format("YYYY-MM-DD"),
        },
      };
      tempState.itineraryParts.push(tempitineraryParts);
    }
    //flight 5
    if (this.state.show5) {
      tempitineraryParts = null;

      tempitineraryParts = {
        from: {
          code: this.state.flight5From.substring(
            this.state.flight5From.indexOf(")") - 3,
            this.state.flight5From.indexOf(")")
          ),
        },
        to: {
          code: this.state.flight5To.substring(
            this.state.flight5To.indexOf(")") - 3,
            this.state.flight5To.indexOf(")")
          ),
        },
        when: {
          date: moment(this.state.flight5StartDate).format("YYYY-MM-DD"),
          // date: this.state.flight5StartDate.format("YYYY-MM-DD"),
        },
      };
      tempState.itineraryParts.push(tempitineraryParts);
    }
    //flight 6
    if (this.state.show6) {
      tempitineraryParts = null;

      tempitineraryParts = {
        from: {
          code: this.state.flight6From.substring(
            this.state.flight6From.indexOf(")") - 3,
            this.state.flight6From.indexOf(")")
          ),
        },
        to: {
          code: this.state.flight6To.substring(
            this.state.flight6To.indexOf(")") - 3,
            this.state.flight6To.indexOf(")")
          ),
        },
        when: {
          date: moment(this.state.flight6StartDate).format("YYYY-MM-DD"),
          // date: this.state.flight6StartDate.format("YYYY-MM-DD"),
        },
      };
      tempState.itineraryParts.push(tempitineraryParts);
    }
    tempState.passengers.ADT = this.state.TotalNumberOfAdult;
    tempState.passengers.CHD = this.state.TotalNumberOfChildren;
    tempState.passengers.INF = this.state.TotalNumberOfInfant;
    tempState.cabinClass = parseInt(this.state.class);
    this.setState({ bodyParameters: tempState });

    if(this.props.searchFlightAgain =="true"){
      localStorage.setItem(
        "searchRequestObj",
        JSON.stringify(this.state.bodyParameters)
      );
      window.location.reload(false);
      return true;
    }

    HttpService.postService(this.state.bodyParameters, "/Availability/Search")
      .then((response) => {
        
        localStorage.setItem(
          "searchRequestObj",
          JSON.stringify(this.state.bodyParameters)
        );
        HttpService.getFlightInformation()
          .then((response) => {
            localStorage.setItem(
              "FlightInformation",
              JSON.stringify(response.data.fareCompares)
            );
          })
          .catch((error) => {
            // console.log(error);
          });
        this.setState({ responseFromServer: response.data });

        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          messageAPI: "Network Error",
          networkError: true,
        });
      });
  };
  ChildPlusButton = () => {
    if (this.state.TotalNumberOfAdult + this.state.TotalNumberOfChildren < 9) {
      let tempState = this.state;
      tempState.TotalNumberOfChildren = this.state.TotalNumberOfChildren + 1;
      if (this.state.TotalNumberOfChildren >= 2) {
        tempState.ChildText = "Children";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.InfantText;
      this.setState({ tempState });
    }
  };
  ChildMinusButton = () => {
    if (this.state.TotalNumberOfChildren > 0) {
      let tempState = this.state;
      tempState.TotalNumberOfChildren = this.state.TotalNumberOfChildren - 1;
      if (this.state.TotalNumberOfChildren <= 1) {
        tempState.ChildText = "Child";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.InfantText;
      this.setState({ tempState });
    }
  };

  numberOfPassengerHandler = (event) => {
    const tempState = this.state.showPassengerSelecter;
    // event.preventDefault();
    this.setState({ showPassengerSelecter: !tempState });
  };

  AdultPlusButton = () => {
    if (this.state.TotalNumberOfAdult + this.state.TotalNumberOfChildren < 9) {
      let tempState = this.state;
      tempState.TotalNumberOfAdult = this.state.TotalNumberOfAdult + 1;
      if (this.state.TotalNumberOfAdult >= 2) {
        tempState.AdultText = "Adults";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.TotalNumberOfInfant;
      this.setState({ tempState });
    }
  };
  AdultMinusButton = () => {
    if (this.state.TotalNumberOfAdult > 1) {
      let tempState = this.state;
      tempState.TotalNumberOfAdult = this.state.TotalNumberOfAdult - 1;
      if (this.state.TotalNumberOfAdult <= 1) {
        tempState.AdultText = "Adult";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.InfantText;
      this.setState({ tempState });
    }
  };
  InfantPlusButton = () => {
    if (this.state.TotalNumberOfInfant < 2) {
      let tempState = this.state;
      tempState.TotalNumberOfInfant = this.state.TotalNumberOfInfant + 1;
      if (this.state.TotalNumberOfInfant >= 2) {
        tempState.InfantText = "Infants";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.InfantText;
      this.setState({ tempState });
    }
  };
  InfantMinusButton = () => {
    if (this.state.TotalNumberOfInfant > 0) {
      let tempState = this.state;
      tempState.TotalNumberOfInfant = this.state.TotalNumberOfInfant - 1;
      if (this.state.TotalNumberOfInfant <= 1) {
        tempState.InfantText = "Infant";
      }
      tempState.passengerText =
        this.state.TotalNumberOfAdult +
        " " +
        this.state.AdultText +
        " , " +
        this.state.TotalNumberOfChildren +
        " " +
        this.state.ChildText +
        " , " +
        this.state.TotalNumberOfInfant +
        " " +
        this.state.InfantText;
      this.setState({ tempState });
    }
  };
  AddFlight = (event) => {
    event.preventDefault();
    if (!this.state.show3) {
      this.setState({ show3: true, showRemoveButton: true });
    } else if (!this.state.show4) {
      this.setState({ show4: true });
    } else if (!this.state.show5) {
      this.setState({ show5: true });
    } else if (!this.state.show6) {
      this.setState({ show6: true });
    } else {
    }
  };
  RemoveFlight = (event) => {
    event.preventDefault();
    if (this.state.show6) {
      this.setState({ show6: false });
    } else if (this.state.show5) {
      this.setState({ show5: false });
    } else if (this.state.show4) {
      this.setState({ show4: false });
    } else if (this.state.show3) {
      this.setState({ show3: false, showRemoveButton: false });
    } else {
    }
  };
  closeDialog = () => {
    if(this.state.messageAPI =="Network Error"){
      localStorage.setItem("holidaysTkn",null);
      window.location.reload(false);
    }
    this.setState({ showDialog: false, messageAPI: null });
  };
  showDialog = () => {
    this.setState({
      responseFromServer: null,
      showDialog: true,
      networkError: false,
    });
  };
  // componentWillMount=()=>{
  //   
  //   var searchObj= JSON.parse(localStorage.getItem("searchRequestObj"));
  //   if(this.props.searchFlightAgain =="true"){
  //     var temp = this.state;
  //     temp.from ={label:DataService.getFullCityName(searchObj.itineraryParts[0].from.code),value:DataService.getFullCityName(searchObj.itineraryParts[0].from.code)} ;
  //     temp.to ={label:DataService.getFullCityName(searchObj.itineraryParts[0].to.code),value:DataService.getFullCityName(searchObj.itineraryParts[0].to.code)} ;
  //     // temp.startDate =searchObj.itineraryParts[0].when.date;
  //     this.setState(temp);
  
  //   }
  // }
  render() {
    let passengerSelecter = null;
    let showRemoveLabel = null;
    let flight3 = null;
    let flight4 = null;
    let flight5 = null;
    let flight6 = null;
    let loading = null;

    if (this.state.isLoading) {
      loading = (
        <div className="Loading-div">
          <div className="Loading">
            <ProgressLoader displayMess="Searching Available Flights" />
          </div>
        </div>
      );
    }

    if (this.state.responseFromServer != null || this.state.networkError) {
      if (this.state.networkError) {
        this.showDialog();
      } else if (this.state.responseFromServer != null) {
        if (this.state.responseFromServer.single.statusAPI === "ERROR") {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else if (
          this.state.responseFromServer.single.statusAPI === "SUCCESS" &&
          this.state.responseFromServer.single.searchResponse.brandedResults
            .itineraryPartBrands[0].length === 0
        ) {
          this.setState({
            messageAPI: this.state.responseFromServer.single.messageAPI,
          });
          this.showDialog();
        } else {
          if (this.state.showOneWay) {
            localStorage.setItem("selectedPreference", "package");
            localStorage.setItem("isMulticity", "no");
            return (
              <Redirect
                to={{
                  pathname: "/flight",
                  props: {
                    result: this.state.responseFromServer,
                    type: "oneWay",
                    category: "package",
                    hotelSearch: this.hotelSearch,
                  },
                }}
              />
            );
          } else {
            localStorage.setItem("selectedPreference", "package");
            localStorage.setItem("isMulticity", "yes");

            return (
              <Redirect
                to={{
                  pathname: "/flight",
                  props: {
                    result: this.state.responseFromServer,
                    category: "multiCity",
                  },
                  category: "multiCity",
                }}
              />
            );
          }
        }
      }
    }
    if (this.state.showRemoveButton) {
      showRemoveLabel = (
        <div className="col-12 col-md-6">
          <span className="minusFlightDiv" onClick={this.RemoveFlight}>
            <RemoveIcon className="minusFlight" />
           {'  '} Remove Flight
          </span>
        </div>
      );
    } else {
      showRemoveLabel = null;
    }
    const airpotList = [
      "Aarhus (AAR), Denmark",
      "Aberdeen Dyce (ABZ)), United Kingdom",
      "Abha (AHB), Saudi Arabia",
      "Abidjan (ABJ), Côte d'Ivoire",
      "Abuja (ABV), Nigeria",
      "Accra (ACC), Ghana",
      "Addis Ababa (ADD), Ethiopia",
      "Adelaide (ADL), Australia",
      "Albany Intl (ALB), United States",
      "Alicante (ALC), Spain",
      "Amman (AMM), Jordan",
      "Amsterdam (AMS), Netherland",
      "Antananarivo (TNR), Madagascar",
      "Aomori(AOJ), Japan",
      "Arba Minch (AMH), Ethiopia",
      "Asmara (ASM), Eritrea",
      "Assosa (ASO), Ethiopia",
      "Athens (ATH), Greece",
      "Atlanta (ATL), United States",
      "Auckland Airport (AKL), New Zealand",
      "Austin (AUS), United States",
      "Avalon AU(AVV), Australia",
      "Axum (AXU), Ethiopia",
      "Ayers Rock(AYQ), Australia ",
      "Bahar Dar (BJR), Ethiopia",
      "Bahrain (BAH), Bahrain",
      "Ballina(BNK), Australia",
      "Bamako (BKO), Mali",
      "Bandung (BDO), Indonesia",
      "Bangkok Suvarnabhumi (BKK), Thailand",
      "Bangui (BGF), Central African Republic",
      "Barcelona (BCN), Spain",
      "Beijing (BJS), China",
      "Beijing (PEK), China",
      "Beirut (BEY), Lebanon",
      "Belo Horizonte, Tancredo Neves Airport (CNF) , Brazil",
      "Bergen (BGO), Norway",
      "Bergenz (XGZ), Austria",
      "Berlin Tegel (TXL), Germany",
      "Bilbao (BIO), Spain",
      "Billund (BLL), Denmark",
      "Birmingham (BHX) , United Kingdom",
      "Blantyre (BLZ), Malawi",
      "Boston (BOS), United States",
      "Boulder (BLD), United States",
      "Brasilia (BSB), Brazil",
      "Brazzaville (BZV), Republic of Congo",
      "Bremen (BRE), Germany",
      "Brisbane (BNE), Australia",
      "Brussels (BRU), Belgium",
      "Bucharest (OTP), Romania",
      "Budapest, Liszt Ferenc Airport (BUD), Hungary",
      "Buenos Aires (EZE), Argentina",
      "Buffalo (BUF), United States",
      "Bujumbura (BJM), Burundi",
      "Burlington (BTV), United States",
      "Cairo (CAI), Egypt",
      "Calgary Intl AB (YYC), Canada",
      "Campo Grande International Airport (CGR), Brazil",
      "Cape Town (CPT), South Africa",
      "Cardiff (CWL) , United Kingdom",
      "Changsha (CSX), China",
      "Charleston (CHS), United States",
      "Charleston (CRW), United States",
      "Charlotte (CLT), United States",
      "Charlottesville (CHO), United States",
      "Chengdu (CTU), China",
      "Chiang Mai (CNX), Thailand",
      "Chicago Ohare (ORD), United States",
      "Chongqing (CKG), China",
      "Christchurch Airport (CHC), New Zealand",
      "Cincinnati (CVG), United States",
      "Cleveland (CLE), United States",
      "Columbia (CAE), United States",
      "Columbus (CMH), United States",
      "Conakry (CKY), Guinea",
      "Copenhagen (CPH), Denmark",
      "Coruna (LCG), Spain",
      "Cotonou (COO), Benin",
      "Cuiaba (CGB), Brazil",
      "Curitiba (CWB), Brazil",
      "Dakar Blaise Diagne Airport (DSS), Senegal",
      "Dakar(DKR), Senegal",
      "Dalian (DLC), China",
      "Dallas FT Worth , TX (DFW), United States",
      "Dammam (DMM), Saudi Arabia",
      "Dar Es Salaam (DAR), Tanzania",
      "Darwin(DRW), Australia",
      "Dayton (DAY), United States",
      "Delhi (DEL), India",
      "Dembidollo (DEM), Ethiopia",
      "Denpasar Bali (DPS), Indonesia",
      "Denver (DEN), United States",
      "Detroit (DTW), United States",
      "Dire Dawa (DIR), Ethiopia",
      "Djibouti (JIB), Djibouti",
      "Doha (DOH), Qatar",
      "Douala (DLA), Cameroon",
      "Dresden (DRS), Germany",
      "Dubai (DXB), UAE",
      "Dubai Al Maktoum (DWC), UAE",
      "Dublin (DUB), Ireland",
      "Durban (DUR), South Africa",
      "Dusseldorf (DUS), Germany",
      "Edinburgh (EDI) , United Kingdom",
      "Edmonton Intl AB (YEG), Canada",
      "El Paso, TX (ELP), United States",
      "Entebbe (EBB), Uganda",
      "Enugu (ENU), Nigeria",
      "Florianopolis (FLN) , Brazil",
      "Fortaleza (FOR), Brazil",
      "Frankfurt (FRA), Germany",
      "Freetown (FNA), Metropolitan Area,Sierra Leone",
      "Friedrichshafen (FDH), Germany",
      "Ft Lauderdale (FLL), United States",
      "Fukuoka (FUK), Japan",
      "Funchal (FNC), Portugal",
      "Gaborone (GBE), Botswana",
      "Gambella (GMB), Ethiopia",
      "Garoua International Airport (GOU), Cameroon",
      "Gassim (ELQ), Saudi Arabia",
      "Geneva (GVA), Switzerland",
      "Glasgow (GLA), United Kingdom",
      "GOA (GOI), INDIA",
      "Goba, Bale - Goba (GOB), Ethiopia",
      "Gode (GDE), Ethiopia",
      "Goiania, Santa Genoveva Airport (GYN), Brazil",
      "Goma (GOM), Democratic Republic of Congo",
      "Gondar (GDQ), Ethiopia",
      "Gothenburg (GOT), Sweden",
      "Grand Rapids (GRR), United States",
      "Graz (GRZ), Austria",
      "Sboro (GSO), United States",
      "Ville Spartanburg  (GSP), United States",
      "Guangzhou (CAN), China",
      "Bosaso (BSA), Somalia",
      "Haikou (HAK), China",
      "Hali (HAS), Saudi Arabia",
      "Halifax (YHZ), Canada",
      "Hamburg (HAM), Germany",
      "Hamilton Island(HTI), Australia",
      "Hannover (HAJ), Germany",
      "Hanoi(HAN), Vietnam",
      "Harare (HRE), Zimbabwe",
      "Hargeisa (HGA), Somaliland",
      "Harrisburg (MDT), United States",
      "Hartford Sprngfld, CT (BDL), United States",
      "Hawassa (AWA), Ethiopia",
      "Helsinki (HEL), Finland",
      "Ho Chi Minh City(SGN), Vietnam",
      "Hobart(HBA), Australia ",
      "Hong Kong (HKG), China",
      "Honolulu (HNL), United States",
      "Houston (HOU), United States",
      "Houston (IAH), United States",
      "Humera (HUE), Ethiopia",
      "Huntsville (HSV), United States",
      "Hyderabad(HYD), India",
      "Ibiza (IBZ), Spain",
      "Iguassu (IGU), Brazil",
      "Indianapolis (IND), United States",
      "Innsbruck (IOB), Austria",
      "Istanbul Ataturk (IST), Turkey",
      "Jacksonville (JAX), United States",
      "Jakarta (CGK), Indonesia",
      "Jakarta(JKT), Indonesia",
      "Jazan (GIZ), Saudi Arabia",
      "Jeddah (JED), Saudi Arabia",
      "Jijiga (JIJ), Ethiopia",
      "Jimma (JIM), Ethiopia",
      "Jinka (BCO), Ethiopia",
      "Johannesburg (JNB), South Africa",
      "Juazeiro Norte (JDO), Brazil",
      "Juba (JUB), South Sudan",
      "Kabri Dar (ABK), Ethiopia",
      "Kaduna (KAD), Nigeria",
      "Kano (KAN), Nigeria",
      "Kansas City (MCI), United States",
      "Khartoum (KRT), Sudan",
      "Kigali (KGL), Rwanda",
      "Kilimanjaro (JRO), Tanzania",
      "Kinshasa Ndjili (FIH), Democratic Republic of Congo",
      "Kisangani (FKI), Democratic Republic of Congo",
      "Klagenfurt (KGV) , Austria",
      "Knoxville (TYS), United States",
      "Koh Samui (USM), Thailand ",
      "kombolcha (DSE), Ethiopia",
      "Kota Kinabalu (BKI), Malaysia",
      "Kristiansand (KRS), Norway",
      "Kuala Lumpur (KUL), Malaysia",
      "Kuching (KCH), Malaysia",
      "Kunming (KMG), China",
      "Kuwait (KWI), Kuwait",
      "Lagos (LOS), Nigeria",
      "Lalibella (LLI), Ethiopia",
      "Langkawi (LGK), Malaysia",
      "Larnaca (LCA), Cyprus",
      "Las Vegas (LAS), United States",
      "Launceston(LST) , Australia",
      "Leeds Bradford (LBA), United Kingdom",
      "Leipzig Halle (LEJ), Germany",
      "Libreville (LBV), Gabon",
      "Lilongwe (LLW), Malawi",
      "Linz (LNZ), Austria",
      "Lisbon (LIS), Portugal",
      "Lome (LFW), Togo",
      "London (LON), United Kingdom",
      "London Heathrow (LHR), United Kingdom",
      "London On (YXU), CANADA",
      "Londrina Airport (LDB), Brazil",
      "Los Angeles (LAX), United States",
      "Louisville (SDF), United States",
      "Luanda (LAD), Angola",
      "Lubumbashi (FBM), Democratic Republic of Congo",
      "Lulea (LLA), Sweden",
      "Lusaka (LUN), Zambia",
      "Lyon, Satolaos Saint Exupery Airport (LYS), France",
      "Maceio, Zumbi dos Palmares Airport (MCZ), Brazil",
      "Madrid (MAD), Spain",
      "Mahe Island (SEZ), Seychelles",
      "Malabo (SSG), Equatorial Guinea",
      "Malmo (MMA), Sweden",
      "Manado (MDC), Indonesia",
      "Manchester(MAN), United Kingdom",
      "Manila (MNL), Philippines",
      "Maputo (MPM), Mozambique",
      "Marseille, Marignane Airport(MRS), France",
      "Mauritius (MRU), Mauritius",
      "Mbuji Mayi (MJM), Democratic Republic of Congo",
      "Medan Kuala Namu(KNO), Indonesia",
      "Medina (MED), Saudi Arabia",
      "Mekelle (MQX), Ethiopia",
      "Melbourne (MEL), Australia",
      "Memphis(MEM), United States",
      "Miami (MIA), United States",
      "Milan Malpensa (MXP), Italy",
      "Minneapolis (MSP), United States",
      "Mombassa (MBA), Kenya",
      "Moncton (YQM), Canada",
      "Monrovia (MLW), Republic of Liberia",
      "MONT TREMBLANT (YTM), Canada",
      "Montreal Trudeau (YUL), Canada",
      "Moroni (HAH), Comoros",
      "Moscow (MOW), Russia",
      "Muenster (FMO), Germany",
      "Mumbai (BOM), India",
      "Munich (MUC), Germany",
      "Muscat (MCT), Oman",
      "Nagoya (NGO), Japan",
      "Nairobi Kenyatta (NBO), Kenya",
      "Nashville (BNA), United States",
      "Natal International Airport (NAT), Brazil",
      "Navegantes, Victor Konder Airport (NVT), Brazil",
      "N'djamena (NDJ), Chad",
      "Ndola (NLA), Zambia",
      "New Orleans (MSY), United States",
      "NEW YORK (JFK), United States",
      "New York (LGA), United States",
      "Newark (EWR), United States",
      "Newark (NYC), United States",
      "Niamey (NIM), Niger",
      "Norfolk VA Beach (ORF), United States",
      "Nosy Be (NOS), Madagascar",
      "Nuremberg (NUE), Germany",
      "Oakland (OAK), United States",
      "Okinawa (OKA), Japan",
      "Oklahoma City (OKC), United States",
      "Orange County (SNA), United States",
      "Orlando (MCO), United States",
      "Osaka Itami (ITM), Japan",
      "Osaka Kansai (KIX), Japan",
      "Oslo (OSL), Norway",
      "Osvaldo Vieira International Airport (OXB), Guinea-Bissau ",
      "Ottawa On (YOW), Canada",
      "Ouagadougou (OUA), Burkina Faso",
      "Palembang (PLM), Indonesia",
      "Palma De Mallorca (PMI), Spain",
      "Paris De Gaulle (CDG), France",
      "Pekanbaru (PKU), Indonesia",
      "Penang (PEN), Malaysia",
      "Perth (PER), Australia",
      "Philadelphia (PHL), United States",
      "Phoenix (PHX), United States",
      "Phuket (HKT), Thailand",
      "Pittsburgh (PIT), United States",
      "Pointe Noire (PNR), Republic of Congo",
      "Portland ME (PWM), United States",
      "Portland OR (PDX), United States",
      "Porto Alegre (POA), Brazil",
      "Porto Portugal (OPO), Portugal",
      "Porto Santo (PXO), Portugal",
      "Prague - Ruzyne Airport(PRG), Czech Republic",
      "Praya Lombok (LOP), Indonesia",
      "Providence (PVD), United States",
      "Pusan (PUS), South Korea",
      "Qaisumah (AQI), Saudi Arabia",
      "Québec (YQB), Canada",
      "Railway Germany (QYG), Germany",
      "Raleigh Durham (RDU), United States",
      "Recife (REC), Brazil",
      "Regina (YQR), Canada",
      "Richmond (RIC), United States",
      "Rio De Janeiro, Santos Dumont Airport (SDU), Brazil",
      "Rio Janeiro Gig (GIG), Brazil",
      "Riyadh (RUH), Saudi Arabia",
      "Roanoke (ROA), United States",
      "Rochester (ROC), United States",
      "Rome (ROM), Italy",
      "Rome Fiumicino (FCO), Italy",
      "Rotterdam (RTM), Netherlands",
      "Sacramento (SMF), United States",
      "Salvador (SSA), Brazil",
      "Salzburg (SZG), Austria",
      "San Antonio (SAT), United States",
      "San Diego (SAN), United States",
      "San Francisco (SFO), United States",
      "Santa Maria Azors (SMA), Portugal",
      "Sao Paulo (GRU), Brazil",
      "Sao Paulo (SAO), Brazil",
      "Sapporo Chitose (CTS), Japan",
      "Saskatoon SK (YXE), Canada",
      "Savannah (SAV), United States",
      "Seattle Tacoma (SEA), United States",
      "Semarang (SRG), Indonesia",
      "Semera (SZE), Ethiopia",
      "Seoul (ICN), South Korea",
      "Seoul (SEL), South Korea",
      "Shanghai (PVG), China",
      "Shanghai (SHA), China",
      "Shantou (SWA), China",
      "Shenzhen (SZX), China",
      "Shire (SHC), Ethiopia",
      "Singapore (SIN), Singapore",
      "Skelleftea (SFT), Sweden",
      "Sofia Airport (SOF), Bulgaria",
      "ST Johns NF (YYT), Canada",
      "St. Louis (STL), United States",
      "St. Polten (POK), Austria",
      "State College (SCE), United States",
      "Stavanger(SVG), Norway",
      "Stockholm (STO), Sweden",
      "Stockholm Arlanda (ARN), Sweden",
      "Stuttgart (STR), Germany",
      "Sultan Hasanuddin (UPG), Indonesia",
      "Sunshine Coast(MCY), Australia",
      "Surabaya (SUB), Indonesia",
      "Sydney (SYD), Australia",
      "Syracuse (SYR), United States",
      "Tabuk (TUU), Saudi Arabia",
      "Taif (TIF), Saudi Arabia",
      "Tampa (TPA), United States",
      "Tel Aviv (TLV), Israel",
      "Tete (TET), Mozambique",
      "The Hague (HAG), Netherlands",
      "Tokyo (TYO), Japan",
      "Tokyo Haneda (HND), Japan",
      "Tokyo Narita (NRT), Japan",
      "Toronto (YTO), Canada",
      "Toronto (YYZ), Canada",
      "Toulouse (TLS), France",
      "Townsville(TSV), Australia",
      "Trondheim (TRD), Norway",
      "Turku (TKU), Finland",
      "Umea (UME), Sweden",
      "Vaasa (VAA), Finland",
      "Valencia (VLC), Spain",
      "Vancouver BC (YVR), Canada",
      "Victoria (YYJ), Canada",
      "Victoria Falls (VFA), Zimbabwe",
      "Vienna (VIE), Austria",
      "Vigo (VGO), Spain",
      "Vilnius, Vilnius Airport(VNO), Lithuania",
      "Warsaw, Chopin Okecie Airport (WAW), Poland",
      "Washington (WAS), United States",
      "Washington Dulles (IAD), United States",
      "Windhoek (WDH), Namibia",
      "Winnipeg MB (YWG), Canada",
      "Wuhan (WUH), China",
      "Xiamen (XMN), China",
      "Yaounde Nsimalen (NSI), Cameroon",
      "Yogyakarta (JOG), Indonesia",
      "Yundum Airport (BJL), Gambia",
      "Zanzibar (ZNZ), Tanzania",
      "Zurich (ZRH), Switzerland",
    ];
    let airportListLable = airpotList.map((Element, index) => {
      return (
        <option className="airportlist" value={Element}>
          {Element}
        </option>
      );
    });
    // if (this.state.showPassengerSelecter) {
    //   passengerSelecter = (
    //     <div
    //       className="numberOfGuestContainer2 col s12"
    //       // style="display: block;"
    //     >
    //       <ul id="numberOfGuests">
    //         <li>
    //           <div className="row">
    //             <div className="col-6">
    //               <span>{this.state.AdultText}</span>
    //             </div>
    //             <div className="col-6">
    //               <div className="top-circle">
    //                 <RemoveIcon
    //                   className="inside-icon-minus"
    //                   onClick={this.AdultMinusButton}
    //                 />
    //               </div>
    //               &nbsp;&nbsp;
    //               <span> {this.state.TotalNumberOfAdult}</span>
    //               &nbsp;&nbsp;
    //               <div className="top-circle">
    //                 <AddIcon
    //                   className="inside-icon-plus"
    //                   onClick={this.AdultPlusButton}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-6">
    //               <span>{this.state.ChildText}</span>
    //             </div>
    //             <div className="col-6">
    //               <div className="top-circle">
    //                 <RemoveIcon
    //                   className="inside-icon-minus"
    //                   onClick={this.ChildMinusButton}
    //                 />
    //               </div>
    //               &nbsp;&nbsp;
    //               <span> {this.state.TotalNumberOfChildren}</span>
    //               &nbsp;&nbsp;
    //               <div className="top-circle">
    //                 <AddIcon
    //                   className="inside-icon-plus"
    //                   onClick={this.ChildPlusButton}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <div className="row">
    //             <div className="col-6">
    //               <span>{this.state.InfantText}</span>
    //             </div>
    //             <div className="col-6">
    //               <div className="top-circle">
    //                 <RemoveIcon
    //                   className="inside-icon-minus"
    //                   onClick={this.InfantMinusButton}
    //                 />
    //               </div>
    //               &nbsp;&nbsp;
    //               <span> {this.state.TotalNumberOfInfant}</span>
    //               &nbsp;&nbsp;
    //               <div className="top-circle">
    //                 <AddIcon
    //                   className="inside-icon-plus"
    //                   onClick={this.InfantPlusButton}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <br />
    //           <br />
    //         </li>
    //       </ul>
    //       <div>
    //         <button
    //           className=" btn-danger closing-button"
    //           onClick={this.numberOfPassengerHandler}
    //         >
    //           <Clear className="material-icons " />
    //         </button>
    //       </div>
    //     </div>
    //   );
    // } else {
    // }
    if (this.state.show3) {
      flight3 = (
        <div>
          <Form.Row>
            <label>Flight 3</label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight3From))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Departure Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight3From}
                    onChange={(e) =>
                      this.setState({ flight3From: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight3From: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight3To))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Arrival Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight3To}
                    onChange={(e) =>
                      this.setState({ flight3To: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight3To: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight3StartDate))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRangeIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ display: "inline" }} className="multi_city">
                  <DatePicker
                    selected={this.state.flight3StartDate}
                    onChange={(date) =>
                      this.setState({ flight3StartDate: date })
                    }
                    minDate={
                      this.state.flight2StartDate
                        ? new Date(this.state.flight2StartDate)
                        : new Date()
                    } //{new Date(this.state.flight1StartDate)}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      );
    }

    if (this.state.show4) {
      flight4 = (
        <div>
          <Form.Row>
            <label>Flight 4</label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight4From))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Departure Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight4From}
                    onChange={(e) =>
                      this.setState({ flight4From: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight4From: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight4To))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Arrival Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight4To}
                    onChange={(e) =>
                      this.setState({ flight4To: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight4To: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight4StartDate))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRangeIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ display: "inline" }} className="multi_city">
                  <DatePicker
                    selected={this.state.flight4StartDate}
                    onChange={(date) =>
                      this.setState({ flight4StartDate: date })
                    }
                    minDate={
                      this.state.flight3StartDate
                        ? new Date(this.state.flight3StartDate)
                        : new Date()
                    } //{new Date(this.state.flight1StartDate)}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      );
    }
    if (this.state.show5) {
      flight5 = (
        <div>
          <Form.Row>
            <label>Flight 5</label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              {/* <Form.Label>From*</Form.Label> */}
              <InputGroup className={(!this.state.formValid&&(!this.state.flight5From))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Departure Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight5From}
                    onChange={(e) =>
                      this.setState({ flight5From: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight5From: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight5To))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Arrival Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight5To}
                    onChange={(e) =>
                      this.setState({ flight5To: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight5To: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight5StartDate))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRangeIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ display: "inline" }} className="multi_city">
                  <DatePicker
                    selected={this.state.flight5StartDate}
                    onChange={(date) =>
                      this.setState({ flight5StartDate: date })
                    }
                    minDate={
                      this.state.flight4StartDate
                        ? new Date(this.state.flight4StartDate)
                        : new Date()
                    } //{new Date(this.state.flight1StartDate)}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      );
    }
    if (this.state.show6) {
      flight6 = (
        <div>
          <Form.Row>
            <label>Flight 6</label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight6From))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Departure Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight6From}
                    onChange={(e) =>
                      this.setState({ flight6From: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight6From: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight6To))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FlightTakeoffSharp />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div className="autocomplete-wrapper">
                  <Autocomplete
                    inputProps={{
                      placeholder: "Arrival Airport",
                      required: true,
                    }}
                    items={airpotList}
                    shouldItemRender={(item, value) =>
                      item
                        .toString()
                        .toLowerCase()
                        .indexOf(value.toString().toLowerCase()) > -1
                    }
                    getItemValue={(item) => item}
                    renderMenu={(item) => (
                      <div Key={item} className="dropdown">
                        {item}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item}
                        className={`item ${
                          isHighlighted ? "selected-item" : ""
                        }`}
                      >
                        {item}
                      </div>
                    )}
                    value={this.state.flight6To}
                    onChange={(e) =>
                      this.setState({ flight6To: e.target.value })
                    }
                    onSelect={(value) => this.setState({ flight6To: value })}
                  />
                </div>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className={(!this.state.formValid&&(!this.state.flight6StartDate))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRangeIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ display: "inline" }} className="multi_city">
                  <DatePicker
                    selected={this.state.flight6StartDate}
                    onChange={(date) =>
                      this.setState({ flight6StartDate: date })
                    }
                    minDate={
                      this.state.flight5StartDate
                        ? new Date(this.state.flight5StartDate)
                        : new Date()
                    } //{new Date(this.state.flight1StartDate)}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </Form.Row>
        </div>
      );
    }
    return (
      <div className="tab-content-widget">
        <Modal
          show={this.state.showDialog}
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="closingModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div id="wrapper-sc">
                <img
                  class="planeImage-sc"
                  src="https://d22j4fzzszoii2.cloudfront.net/images/1521/2314/EthiopainAirlinesPlane.png"
                  width="633"
                  height="338"
                  alt="Ethiopain Airlines"
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h2> {this.state.messageAPI} </h2>
            {/* <h2>sorry something went wrong.</h2> */}
            <p>
              Sorry we can't find your flight, please try again or change your
              search criteria
            </p>
          </Modal.Body>
        </Modal>

        {loading}
        <Form id="multicityForm" noValidate onSubmit={this.search} method="Post">
          <div>
          <Form.Row>
         {!this.state.formValid &&  
          <Alert className= "alertField"  variant={"danger"}>
            Please select all required fields.
          </Alert> 
         }
            </Form.Row>
            <Form.Row>
              <label>Flight 1</label>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <InputGroup  className={(!this.state.formValid&&(!this.state.flight1From))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="departureAirport">
                      <FlightTakeoffSharp />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Departure Airport",
                        required: true,
                      }}
                      items={airpotList}
                      shouldItemRender={(item, value) =>
                        item
                          .toString()
                          .toLowerCase()
                          .indexOf(value.toString().toLowerCase()) > -1
                      }
                      getItemValue={(item) => item}
                      renderMenu={(item) => (
                        <div Key={item} className="dropdown">
                          {item}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item}
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item}
                        </div>
                      )}
                      value={this.state.flight1From}
                      onChange={(e) =>
                        this.setState({ flight1From: e.target.value })
                      }
                      onSelect={(value) =>
                        this.setState({ flight1From: value })
                      }
                    />
                  </div>
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup className={(!this.state.formValid&&(!this.state.flight1To))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="departureAirport">
                      <FlightTakeoffSharp />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Arrival Airport",
                        required: true,
                      }}
                      items={airpotList}
                      shouldItemRender={(item, value) =>
                        item
                          .toString()
                          .toLowerCase()
                          .indexOf(value.toString().toLowerCase()) > -1
                      }
                      getItemValue={(item) => item}
                      renderMenu={(item) => (
                        <div Key={item} className="dropdown">
                          {item}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item}
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item}
                        </div>
                      )}
                      value={this.state.flight1To}
                      onChange={(e) =>
                        this.setState({ flight1To: e.target.value })
                      }
                      onSelect={(value) => this.setState({ flight1To: value })}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <InputGroup className={(!this.state.formValid&&(!this.state.flight1StartDate))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <DateRangeIcon />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div style={{ display: "inline" }} className="multi_city">
                    <DatePicker
                      selected={this.state.flight1StartDate}
                      onChange={(date) =>
                        this.setState({ flight1StartDate: date })
                      }
                      minDate={new Date()} //{new Date(this.state.flight1StartDate)}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <label>Flight 2</label>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <InputGroup className={(!this.state.formValid&&(!this.state.flight2From))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="departureAirport">
                      <FlightTakeoffSharp />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Departure Airport",
                        required: true,
                      }}
                      items={airpotList}
                      shouldItemRender={(item, value) =>
                        item
                          .toString()
                          .toLowerCase()
                          .indexOf(value.toString().toLowerCase()) > -1
                      }
                      getItemValue={(item) => item}
                      renderMenu={(item) => (
                        <div Key={item} className="dropdown">
                          {item}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item}
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item}
                        </div>
                      )}
                      value={this.state.flight2From}
                      onChange={(e) =>
                        this.setState({ flight2From: e.target.value })
                      }
                      onSelect={(value) =>
                        this.setState({ flight2From: value })
                      }
                    />
                  </div>
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col}>
                <InputGroup className={(!this.state.formValid&&(!this.state.flight2To))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="departureAirport">
                      <FlightTakeoffSharp />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div className="autocomplete-wrapper">
                    <Autocomplete
                      inputProps={{
                        placeholder: "Arrival Airport",
                        required: true,
                      }}
                      items={airpotList}
                      shouldItemRender={(item, value) =>
                        item
                          .toString()
                          .toLowerCase()
                          .indexOf(value.toString().toLowerCase()) > -1
                      }
                      getItemValue={(item) => item}
                      renderMenu={(item) => (
                        <div Key={item} className="dropdown">
                          {item}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item}
                          className={`item ${
                            isHighlighted ? "selected-item" : ""
                          }`}
                        >
                          {item}
                        </div>
                      )}
                      value={this.state.flight2To}
                      onChange={(e) =>
                        this.setState({ flight2To: e.target.value })
                      }
                      onSelect={(value) => this.setState({ flight2To: value })}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <InputGroup className={(!this.state.formValid&&(!this.state.flight2StartDate))?"errorBorder mb-3":"mb-3"}>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <DateRangeIcon />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <div style={{ display: "inline" }} className="multi_city">
                    <DatePicker
                      selected={this.state.flight2StartDate}
                      onChange={(date) =>
                        this.setState({ flight2StartDate: date })
                      }
                      minDate={
                        this.state.flight1StartDate
                          ? new Date(this.state.flight1StartDate)
                          : new Date()
                      } //{new Date(this.state.flight1StartDate)}
                    />
                  </div>
                </InputGroup>
              </Form.Group>
            </Form.Row>
            {flight3}
            {flight4}
            {flight5}
            {flight6}
            <Form.Row>
              <Form.Group as={Col}>
                <div className="col-12 col-md-6">
                  <span className="addFligtDiv" onClick={this.AddFlight} >
                    <AddIcon className="addFlight"/>
                  { "  " }Add Flight
                  </span>
                </div>
                <div>{showRemoveLabel}</div>
              </Form.Group>
            </Form.Row>
          </div>
          <Form.Row>
            <Form.Group as={Col}>
              {/* <Form.Label>Travelers *</Form.Label> */}
              <InputGroup className={(!this.state.formValid&&(!this.state.passengerText))?"errorBorder mb-3":"mb-3"}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="TravelersRoom">
                    <GroupAdd />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  value={this.state.passengerText}
                  name="TravelersRoom"
                  readOnly
                  className="trip"
                  required
                  aria-label="DepartureAirport"
                  aria-describedby="DepartureAirport"
                  onClick={this.numberOfPassengerHandler}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className={ "mb-3"}>
                <select
                  className="form-control select-widget"
                  onChange={(e) => this.setState({ class: e.target.value })}
                >
                  <option defaultValue value={3}>
                    All Class
                  </option>
                  <option value={1}>Economy</option>
                  <option value={2}>Business</option>
                </select>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup className="mb-3 ">
                <button className="search-btn2 btn btn-success" type="submit">
                  Search
                </button>
              </InputGroup>
              
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <InputGroup>{passengerSelecter}</InputGroup>
            </Form.Group>
          </Form.Row>

          <Modal
          show={this.state.showPassengerSelecter}
          onHide={this.numberOfPassengerHandler}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: "1em" }}>
              <div className="row">
                <div className="col-6">
                  <span>{this.state.AdultText}</span>
                </div>
                <div className="col-6 mb-2">
                  <div className="top-circle">
                    <button className="btn" onClick={this.AdultMinusButton}>
                      <RemoveIcon className="inside-icon-minus" />
                    </button>
                  </div>
                  &nbsp;&nbsp;
                  <span> {this.state.TotalNumberOfAdult}</span>
                  &nbsp;&nbsp;
                  <div className="top-circle">
                    <button className="btn" onClick={this.AdultPlusButton}>
                      <AddIcon className="inside-icon-plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <span>{this.state.ChildText}</span>
                </div>
                <div className="col-6 mb-2">
                  <div className="top-circle">
                    <button className="btn" onClick={this.ChildMinusButton}>
                      <RemoveIcon className="inside-icon-minus" />
                    </button>
                  </div>
                  &nbsp;&nbsp;
                  <span> {this.state.TotalNumberOfChildren}</span>
                  &nbsp;&nbsp;
                  <div className="top-circle">
                    <button className="btn" onClick={this.ChildPlusButton}>
                      <AddIcon className="inside-icon-plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <span>{this.state.InfantText}</span>
                </div>
                <div className="col-6 mb-2">
                  <div className="top-circle">
                    <button className="btn" onClick={this.InfantMinusButton}>
                      <RemoveIcon className="inside-icon-minus" />
                    </button>
                  </div>
                  &nbsp;&nbsp;
                  <span> {this.state.TotalNumberOfInfant}</span>
                  &nbsp;&nbsp;
                  <div className="top-circle">
                    <button className="btn" onClick={this.InfantPlusButton}>
                      <AddIcon className="inside-icon-plus" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="search-btn2 btn btn-success"
              onClick={this.numberOfPassengerHandler}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>



        </Form>
      </div>
    );
  }
}
export default MultiCity;
