import React, { Component } from "react";
import "./holidays-type.css";
import { Link } from "react-router-dom";
import { parseJSON } from "jquery";
import { HttpService } from "../../../services/http/http-service";

//#7793 holidays type commited
class HolidaysTypeCatagory extends Component {
  constructor(props) {
    super(props);
    if (JSON.parse(localStorage.getItem("packageCatagories"))) {
      let localitem = JSON.parse(localStorage.getItem("packageCatagories"));
      this.state = {
        items: localitem[0],
        isLoaded: true,
      };
    } else {
      this.state = {
        items: [],
        isLoaded: false,
      };
    }
  }

  async componentDidMount() {
    let catagory;
    if (this.props.match.params.catagory == "Holidays Type") {
      catagory = "Holiday Type";
    } else {
      catagory = "Special Offers";
    }

    if (JSON.parse(localStorage.getItem("packageCatagories"))) {
    } else {
      this.getPackageCatagories();
    }
  }

  getPackageCatagories() {
    let relativePath = "/MasterDatas/GetMasterDatas?SearchType=PACKAGECATEGORY";

    this.setState({ isLoading: true });
    HttpService.getServicePackage(relativePath)
      .then((response) => {
        this.setState({ isLoading: false });
        let responseData = response.data;
        if (response.status === 200) {
          localStorage.setItem(
            "packageCatagories",
            JSON.stringify(responseData.packageCatagories)
          );
          this.setState({
            items: responseData.packageCatagories[0],
          });
        } else {
          // console.log("responseData error1 ");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        // console.log("responseData error2 ");
      });
  }

  showPackageLists = (a) => {
    //  this.router.navigate(['/package-details']);
    let path = "/package-lists/" + a;
    window.location.assign(path);
  };

  render() {
    var { isLoaded, items } = this.state;
    if (!isLoaded) {
      return (
        <div>
          loading Holidays Type...{isLoaded}
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="special-offer container pt-0">
          {(items.packageTypes)&&<>
          <h1 className="text-center special-offer-head">
            {this.props.match.params.catagory}
          </h1>

          <div class="row row-cols-1 row-cols-md-2">
            {items.packageTypes.map((item) => (
              <div class="col-lg-4 col-md-6 mb-4" key={item} value={item}>
                <div class="card">
                  <div
                    className="card-img"
                    style={{ backgroundImage: "url(" + item.imagePath + ")" }}
                  ></div>
                  <div class="card-body">
                    <h5 class="card-title">{item.name}</h5>
                    <button
                      type="button"
                      className="btn et-btn"
                      onClick={() => this.showPackageLists(item.id)}
                    >
                      {" "}
                      MORE{" "}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </>}
        </div>
      );
    }
  }
}

export default HolidaysTypeCatagory;
