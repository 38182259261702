import "./package-lists.css";
import React, { Component } from "react";
import queryString from "query-string";
import { HttpService } from "../../../../services/http/http-service";
import JwPagination from "jw-react-pagination";
import MataDecorator from "../../../shared/meta-decorator/meta-helmet";

import Figure from 'react-bootstrap/Figure'
import { isBrowser, isMobile } from "react-device-detect";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';
import ProgressLoader from "../../../shared/progress-loading/progress-loader";
class PackageLists extends Component {
    constructor(props) {
        super(props);
        this.onChangePage = this.onChangePage.bind(this);
        let search = queryString.parse(this.props.location.search);
        let currentpageURL = window.location.href
        this.state = {
            pageURL: currentpageURL,
            items: [],
            pageOfItems: [],
            isLoaded: false,
            search: search,
        };
    }

    onChangePage(pageOfItems) {
        window.scrollTo({ top: 50, behavior: "smooth" });
        this.setState({ pageOfItems });
    }

    async componentDidMount() {
        this.getPackageList();
    }

    getPackageList() {

        const relativePath2 = "/api/Packages/ByContent";

        var { isLoaded, items, search } = this.state;
        let countryCode2 = "countryCode";
        if (search.countryCode) {
            countryCode2 = search.countryCode;
        } else {
            countryCode2 = "countryCode";
        }
        let data = {
            SearchBy: search.SearchType ? search.SearchType : "Type",
            Id: this.props.match.params.packageTypeId,
            CountryCode: countryCode2,
        };

        HttpService.postServicePackage(data, relativePath2)
            .then((response) => {
                let responseData = response.data;
                this.setState({
                    isLoaded: true,
                });

                if (responseData.list) {
                    this.setState({
                        items: responseData.list[0].packages,
                    });

                }
            })
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                });
            });
    }

    showPackageDetails = (packageData) => {
        let search =
            "?packageName=" +
            packageData.package.name +
            "&packageId=" +
            packageData.package.id;
        let path = "/package-details/" + search;

        window.location.assign(path);
    };

    render() {
        var { isLoaded, items, search } = this.state;
        if (!isLoaded) {
            return (
                <div className="mt-5 pt-5">
                    <ProgressLoader displayMess={"Loading " + search.packageTypeName} />
                </div>
            );
        } else {
            return (
                <>

                    {(items.length < 1) &&
                        <>
                            <div className="pt-5 text-center mt-5 ">
                                <p className="text-center h4">Sorry no package found !</p>
                                <a className="btn mt-3 border" href="/">
                                    Back To Home
                                </a>
                            </div>
                        </>
                    }
                    {(items.length > 0) &&
                        <>

                            <MataDecorator
                                metaInfo={
                                    {
                                        title: "Ethiopian Holidays | Special Offers",
                                        description: "Are you planning your holiday?Ethiopian Holidays offer the best and most exciting holiday destinations.Enjoy the greatest travel experience with Africa's Best Airline.",
                                        url: this.state.pageURL,
                                    }
                                }
                            />
                            <div className="pb-0  mb-100">


                                <Figure>
                                    <Figure.Image
                                        alt={"packageDetails.package.caption"}
                                        src={items[0].package.packageType.imagePath}
                                    />
                                    <Figure.Caption>
                                        <>

                                            {(items[0].package.packageType.bannerCaption) &&
                                                <>
                                                    {isMobile &&
                                                        <span className={" float-left   "}>
                                                            <h2 className={" float-left   packBannnerCaptionMObile   "}  >
                                                                {parse(items[0].package.packageType.bannerCaption)}
                                                            </h2>
                                                        </span>
                                                    }
                                                    {isBrowser && <>
                                                        <span className={" float-left bottom packBannnerCaption"}>
                                                            <h2 className={" float-left bottom packbackgroundbanner packbannerHeadFont"}  >
                                                                {parse(items[0].package.packageType.bannerCaption)}
                                                            </h2>
                                                        </span>
                                                    </>
                                                    }
                                                </>
                                            }
                                        </>
                                    </Figure.Caption>
                                </Figure>
                            </div>

                            <div class="text-center  container pt-5 pb-3 mt-3">
                                <h1 className="text-center ">{search.packageTypeName}</h1>

                                <div class="row row-cols-1 row-cols-md-2 mt-5 card__wrap--outer" ref="initial">
                                    {this.state.pageOfItems.map((item) => (
                                        <div class="col-lg-4 col-md-6 mb-4 card__wrap--inner" key={item} value={item}>
                                            <div
                                                class="card packageCard"
                                                style={{ cursor: "pointer !important" }}
                                                onClick={() => this.showPackageDetails(item)}
                                            >

                                                <div
                                                    className="img-fluid "
                                                    style={{
                                                        cursor: "pointer !important",
                                                        maxHeight: "180px"
                                                    }}>
                                                    <LazyLoadImage className="  img-fluid img-pkglist"
                                                        style={{ cursor: "pointer !important", maxHeight: "140px" }}
                                                        alt={item.package.caption}
                                                        effect="blur"
                                                        src={item.package.imagePath}
                                                    />
                                                </div>
                                                <div class="card-body pb-1 pt-1">
                                                    <h5 class="card-title pb-1">{item.package.name}</h5>

                                                    <p class="card-title pt-0">
                                                        Duration: {item.package.numberOfDays} day(s)
                                                    </p>
                                                    <button className="btn btn-outline-success my-2" type="button">See details</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <JwPagination
                                    pageSize={6}
                                    items={items}
                                    onChangePage={this.onChangePage}
                                />
                            </div>
                        </>
                    }
                </>

            );
        }
    }
}

export default PackageLists;
