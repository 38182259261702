import React, { Component } from "react";

import { Link } from "react-router-dom";
import "./sideDrawer.css";
import { Card, Accordion, Button } from "react-bootstrap";
import { HttpService } from "../../../../services/http/http-service";
import Autosuggest from "react-autosuggest";
import Search from "@material-ui/icons/Search";

var languages = [];

const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
        ? []
        : languages.filter(
            (lang) => lang.toLowerCase().slice(0, inputLength) === inputValue
        );
};
const renderSuggestion = (suggestion) => (
    <span className="name">{suggestion}</span>
);

const getSuggestionValue = (suggestion) => suggestion;
class sideDrawer extends Component {
    constructor(props) {
        super(props);
        // let Destinations2 = JSON.parse(localStorage.getItem("Destinations"));
        // let packageCatagories2 = JSON.parse(
        //   localStorage.getItem("packageCatagories")
        // );

        this.state = {
            continents: [],
            showSearchBox: false,
            packageCatagories: [],
            value: "",
            suggestions: [],
        };

        // let Destinations2 = JSON.parse(localStorage.getItem("Destinations"));
        // let packageCatagories2 = JSON.parse(
        //   localStorage.getItem("packageCatagories")
        // );
        // if (Destinations2) {
        //   this.state = {
        //     continents: Destinations2,
        //   };
        // }
        // if (packageCatagories2) {
        //   this.state = {
        //     packageCatagories: packageCatagories2,
        //   };
        // }
    }

    async componentDidMount() {

        //debugger

        var destinations = JSON.parse(localStorage.getItem("Destinations"));
        if (destinations) {
            this.setState({
                continents: destinations.data,
            });
        } else {
            this.getDestinations();
        }

        let etPackages = JSON.parse(localStorage.getItem("ethiopianPackages"));
        if (etPackages) {
            this.setState({
                ethiopianPackages: etPackages.packages
            });
        } else {
            this.getEthiopianPackages();
        }

        if (JSON.parse(localStorage.getItem("packageCatagories"))) {
            this.setState({
                packageCatagories: JSON.parse(
                    localStorage.getItem("packageCatagories")
                ),
            });
        } else {
            // this.getPackageCatagories();
        }
        await this.getSerchBox();

        await HttpService.getServicePackage("/Availability/GetPackageNames")
            .then((response) => {
                languages = response.data.packageNames;
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    getSerchBox() {
        // console.log("window.document.location.pathname", window.document.location);

        var searchingPaths = [
            { path: "package-lists" },
            { path: "package-details" },
            { path: "destination" },
            { path: "country" },
            { path: "search-packages" },
            { path: "home" },
        ];

        for (let i = 0; i < searchingPaths.length; i++) {
            if (window.document.location.pathname.includes(searchingPaths[i].path)) {
                // console.log(
                //   "window.document.location.pathname includes",
                //   window.document.location.pathname.includes(searchingPaths[i].path)
                // );
                this.setState({ showSearchBox: true });
            }
        }
        if (window.document.location.pathname == "/") {
            this.setState({ showSearchBox: true });
        }
    }

    search(searchString) {
        // console.log("search method");
        // console.log("search method");
        // console.log(searchString);
        // console.log(searchString);
        var relativePathUrl = "/search-packages?search=" + this.state.serachPackage;
        // console.log("relativePathUrl");
        // console.log(relativePathUrl);

        if (this.state.serachPackage != null) {
            // window.location.replace("search-packages?search="+this.state.serachPackage)
            window.location.replace(relativePathUrl);
        }
    }
    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
        });
        if (method === "click" || method === "enter") {
            this.search(newValue);
        }
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value),
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getDestinations() {
        const relativePath = "/MasterDatas/GetMasterDatas?SearchType=CONTINENT";
        HttpService.getServicePackage(relativePath)
            .then((response) => {
                let responseData = response.data;
                localStorage.setItem(
                    "Destinations",
                    JSON.stringify(responseData.continents)
                );
                this.setState({
                    isLoaded: true,
                    continents: responseData.continents,
                });
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getEthiopianPackages() {
        const relativePath2 = "/api/Packages/GetpackageInfo";
        HttpService.postServicePackage({ CountryCode: "ET" }, relativePath2)
            .then((response) => {
                this.setState({ isLoading: false });
                let responseData = response.data;
                localStorage.setItem(
                    "ethiopianPackages",
                    JSON.stringify(responseData.list)
                );
                this.setState({
                    ethiopianPackages: responseData.list,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                // console.log("responseData error2 ");
            });
    }

    getPackageCatagories() {
        const relativePath3 =
            "/MasterDatas/GetMasterDatas?SearchType=PACKAGECATEGORY'";
        HttpService.getServicePackage(relativePath3)
            .then((response) => {
                this.setState({ isLoading: false });
                let responseData = response.data;
                localStorage.setItem(
                    "packageCatagories",
                    JSON.stringify(responseData.packageCatagories)
                );
                this.setState({
                    packageCatagories: responseData.packageCatagories,
                });
            })
            .catch((error) => {
                console.log('Error getting master data: ', error);
            });
    }

    showDestination = (a, currentDestinations) => {
        localStorage.setItem(
            "currentDestinations",
            JSON.stringify(currentDestinations)
        );
        let path = "/destination/" + a;
        window.location.assign(path);
    };

    showPackageLists = (packageTypeId, packageTypeName) => {
        let search =
            "?packageTypeName=" +
            packageTypeName +
            "&packageTypeId=" +
            packageTypeId;
        let path = "/package-lists/" + packageTypeId + "/" + search;
        window.location.assign(path);
    };

    render() {
        var { continents, packageCatagories } = this.state;
        const { value, suggestions } = this.state;

        let drawerClasses = "side-drawer";
        if (this.props.show) {
            drawerClasses = "side-drawer open";
        }

        const inputProps = {
            placeholder: "Search Packages",
            value,
            onChange: this.onChange,
            type: "search",
        };
        return (
            <nav className={drawerClasses} style={{ overflow: " scroll" }}>
                <Link to="/">
                    {" "}
                    <img
                        className="img-fluid logo-img m-2"
                        src={window.location.origin + "/assets/image/holidays.png"}
                    />
                </Link>
                <Card className="mobileMenuCard">
                    <Card.Header
                        style={{ textAlign: "center", borderTop: "1px solid #33333342" }}
                        className="mobileMenuHeader">
                        <div style={{ display: "inline" }}>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                            />
                            <button className="search-button" onClick={() => this.search()}>
                                <Search />
                            </button>
                        </div>

                    </Card.Header>
                </Card>

                <Accordion>
                    {/* 
          <Card className="mobileMenuCard">
            <Card.Header
              style={{ textAlign: "left", borderTop: "1px solid #33333342" }}
              className="mobileMenuHeader"
            >
              <Accordion.Toggle
                as={Button}
                variant="link"
                className="mobileMenuButton"
                onClick={() => {
                  window.location.assign("/trip-planner");
                }}
              >
                Trip Planner
              </Accordion.Toggle>
            </Card.Header>
          </Card> */}

                    <Card className="mobileMenuCard">
                        <Card.Header
                            style={{ textAlign: "left" }}
                            className="mobileMenuHeader"
                        >
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="mobileMenuButton"
                            >
                                Destinations
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body
                                style={{ textAlign: "center", backgroundColor: "whitesmoke" }}
                            >
                                <ul className="list-group list-group-flush">
                                    {continents.map((item) => (
                                        <li
                                            className="list-group-item sideNavItem d-flex m-1 p-1 pl-4"
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "whitesmoke",
                                                color: "green",
                                            }}
                                            onClick={() => this.showDestination(item.name, item)}
                                        >
                                            {item.name}
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {packageCatagories.map((item) => (
                        <Card className="mobileMenuCard">
                            <Card.Header
                                style={{ textAlign: "left" }}
                                className="mobileMenuHeader"
                            >
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey={item.name}
                                    className="mobileMenuButton"
                                // style={{ padding: "0px" }}
                                >
                                    {item.name}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={item.name}>
                                <Card.Body
                                    style={{ textAlign: "center", backgroundColor: "whitesmoke" }}
                                >
                                    <ul className="list-group list-group-flush">
                                        {item.packageTypes.map((item) => (
                                            <li
                                                style={{
                                                    textAlign: "center",
                                                    backgroundColor: "whitesmoke",
                                                    color: "green",
                                                }}
                                                onClick={() =>
                                                    this.showPackageLists(item.id, item.name)
                                                }
                                                className="list-group-item sideNavItem d-flex m-1 p-1 pl-4"
                                            >
                                                {item.name}
                                            </li>
                                        ))}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}




                    <Card className="mobileMenuCard">
                        <Card.Header
                            style={{ textAlign: "left", borderTop: "1px solid #33333342" }}
                            className="mobileMenuHeader"
                        >
                            <Accordion.Toggle
                                as={Button}
                                variant="link"

                            >
                                <a className="text-green"
                                    target='_blank'
                                    style={{ color: "green" }}
                                    className="mobileMenuButton" href="https://www.ethiopianairlines.com/aa/book/booking/ethiopian-airlines-conventions"
                                >

                                    MICE Packages
                                </a>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>

                    <Card className="mobileMenuCard">
                        <Card.Header
                            style={{ textAlign: "left", borderTop: "1px solid #33333342" }}
                            className="mobileMenuHeader"
                        >
                            <Accordion.Toggle
                                as={Button}
                                variant="link"

                            >
                                <a className="text-green"
                                    target='_blank'
                                    style={{ color: "green" }}
                                    className="mobileMenuButton" href="https://www.ethiopianairlines.com/aa/book/special-deals/medical-travel"
                                >

                                    Medical Travel
                                </a>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>

                    <Card className="mobileMenuCard">
                        <Card.Header
                            style={{ textAlign: "left", borderTop: "1px solid #33333342" }}
                            className="mobileMenuHeader"
                        >
                            <Accordion.Toggle
                                as={Button}
                                variant="link"

                            >
                                <a className="text-green"
                                    target='_self'
                                    style={{ color: "green" }}
                                    className="mobileMenuButton" href="/about-us"
                                >

                                    About Us
                                </a>
                            </Accordion.Toggle>
                        </Card.Header>
                    </Card>
                </Accordion>
            </nav>
        );
    }
}

export default sideDrawer;
