import axios from "axios";
export class HttpService {

    flightApiHolidays = '';
    hotelApiHolidays = '';
    packageApiHolidays = '';
    packageApiMarketplace = '';
    paymentApiHolidays = '';
    masterdataApiHolidays = '';

    static getHeaderAndURL = () => {
        // let airportUrl = "https://aks-cluster-agc.ethiopianairlines.com/ethiopianpartnershome/GetAirportMasterData"; // Agency Portal Cert
        let airportUrl =
            "https://partners.ethiopianairlines.com/ethiopianpartnershome/GetAirportMasterData"; // Agency Portal Prod


        if (process.env.NODE_ENV === "development") {
            this.flightApiHolidays = 'http://localhost:4000';
            this.hotelApiHolidays = 'http://localhost:3000';
            this.packageApiHolidays = 'http://localhost:5000';
            this.packageApiMarketplace = 'http://localhost:8100';
            this.paymentApiHolidays = 'http://localhost:8222';
            this.masterdataApiHolidays = 'http://localhost:7222';

        } else if (process.env.NODE_ENV === "production") {

            // CERT URL -- to be uncommented for local testing
            // let baseUrl = "https://aks-cluster-dev.ethiopianairlines.com";

            // PROD URL
            var baseUrl = 'https://m.ethiopianairlines.com';
            this.flightApiHolidays = `${baseUrl}/flightapi-etholidays`;
            this.hotelApiHolidays = `${baseUrl}/hotelapi-etholidays`;
            this.packageApiHolidays = `${baseUrl}/packageapi-etholidays`;
            this.packageApiMarketplace = `${baseUrl}/packageapi-marketplace`;
            this.paymentApiHolidays = `${baseUrl}/paymentapi-etholidays`;
            this.masterdataApiHolidays = `${baseUrl}/masterdataapi-etholidays`;
        }

        let token = this.getToken();
        let headers = {
            Authorization: "Bearer " + token,
            ContentType: "application/json",
            Accept: "application/json",
            'cache-control': "no-cache"
        };
        return {
            flightApiHolidays: this.flightApiHolidays,
            hotelApiHolidays: this.hotelApiHolidays,
            packageApiHolidays: this.packageApiHolidays,
            packageApiMarketplace: this.packageApiMarketplace,
            paymentApiHolidays: this.paymentApiHolidays,
            masterdataApiHolidays: this.masterdataApiHolidays,
            token: token,
            headers: headers,
            airportUrl: airportUrl,
        };
    };
    //static  getAirportList= ()=> {
    //    let headerAndURL = this.getHeaderAndURL();
    //    return axios.get(headerAndURL.airportUrl ,{headers:headerAndURL.headers});
    //}

    /*
      Note:
      *-etholidays APIs source code is found at https://ethiopianairlines.visualstudio.com/Market%20Place/_git/K8S
      E.g. flightapi-etholidays/v1.0/api, packageapi-etholidays/V1.0/api
  
      *-marketplace APIs source is found at https://ethiopianairlines.visualstudio.com/Market%20Place/_git/MarketPlace_K8s
  
      */

    static getService = (relativePath, queryString = "") => {
        let headerAndUrl = this.getHeaderAndURL();
        let baseUrl = headerAndUrl.flightApiHolidays + "/V1.0/api";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndUrl.headers,
        });
    };
    static postService = (requestData, relativePath, queryString = "") => {
        //debugger
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.flightApiHolidays + "/V1.0/api";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServiceHotel = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.hotelApiHolidays + "/V1.0/api";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };
    static postServiceHotel = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.hotelApiHolidays + "/V1.0/api";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };



    static getServicePackageBooking = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.packageApiHolidays + "/V1.0";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServicePackageBooking = (
        requestData,
        relativePath,
        queryString = ""
    ) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.packageApiHolidays + "/V1.0";
        localStorage.setItem("select", JSON.stringify(headerAndURL));
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServicePackage = (relativePath, queryString = "") => {

        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.packageApiMarketplace + "/V1.0";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServicePackage = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.packageApiMarketplace + "/V1.0";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServiceDefualt = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.paymentApiHolidays + "/V1.0/";
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServiceDefualt = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.paymentApiHolidays + "/V1.0";
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static getServicePayment = (relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.masterdataApiHolidays;
        return axios.get(baseUrl + relativePath + queryString, {
            headers: headerAndURL.headers,
        });
    };

    static postServicePayment = (requestData, relativePath, queryString = "") => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.masterdataApiHolidays;
        return axios.post(baseUrl + relativePath + queryString, requestData, {
            headers: headerAndURL.headers,
        });
    };

    static postServiceHoldResr = () => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.flightApiHolidays + "/V1.0/api/Purchase/HoldBNPL";
        let holdOp = "BNPL_24Hrs"; //production
        if (
            window.location.hostname.includes("localhost") ||
            window.location.hostname.includes("test")
        ) {
            holdOp = "BNPL_48Hrs"; //for test
        }
        var requestData = {
            paymentRequired: false,
            payment: null,
            holdOptionId: holdOp,
            cookieSabreDataRequest: {
                sessionId: localStorage.getItem("sessionId"),
            },
        };

        return axios.post(baseUrl, requestData, {
            headers: headerAndURL.headers,
        });
    };



    // static getIPService = (relativePath, queryString = "") => {
    //   let token = this.getToken();
    //   let baseUrl = "https://ipapi.co";
    //   return axios.get(baseUrl + relativePath + queryString, {
    //     headers: {
    //       Authorization: "Bearer " + token, //the token is a variable which holds the token
    //       //Isdevelopment: "true",
    //       Isdevelopment: "false",
    //       ContentType: "application/json",
    //       Accept: "application/json",
    //     },
    //   });
    // };

    static getIPService = (ipData) => {
        const publicIp = require("public-ip");
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl = headerAndURL.masterdataApiHolidays + "/api";

        var relativePath2 = "IpDetail/Get?IpAddress=" + ipData;

        return axios.get(baseUrl + relativePath2, {
            headers: headerAndURL.headers,
        });
    };
    static getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    
    static postEmail = (requestData) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.masterdataApiHolidays + "/api/Subscription/add";

        return axios.post(baseUrl, requestData, {
            headers: {
                headers: headerAndURL.headers,
            },
        });
    };
    static getFlightInformation = () => {
        let headerAndURL = this.getHeaderAndURL();

        let baseUrl =
            headerAndURL.flightApiHolidays +
            "/V1.0/api/BrandFare/List?memoryCacheRefresh=false";
        return axios.get(baseUrl, {
            headers: {
                headers: headerAndURL.headers,
            },
        });
    };
    static postServiceTripPlanner = (requestData) => {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.masterdataApiHolidays + "/api/TripPlanner/Add";
        return axios.post(baseUrl, requestData, {
            headers: headerAndURL.headers,
        });
    };

    

    static getToken = () => {
        let xsrfToken = this.getCookie("XSRF-TOKEN"); //*get AntiforgeryToken from Cookies

        // Get token from localstorage
        let token = this.getWithExpiry("holidaysTkn");

        if (token) {
            return token;
        }
        else {
            axios.get("Home/Token", {
                headers: {
                    "Holiday-X-CSRF-TOKEN": xsrfToken,
                },
            }).then(response => {

                if (response && response.data !== null) {
                    
                    this.setWithExpiry("holidaysTkn", response.data.token, response.data.expiry*1000)

                    return response.data.token
                };
            });
        }
    };

    static setWithExpiry = (key, value, ttl) => {
        const now = new Date();
        const item = {
            value,
            expiry: now.getTime() + ttl // Expiry time in milliseconds
        };

        localStorage.setItem(key, JSON.stringify(item));
    };

    static getWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key); // Remove expired item

            return null;
        }

        return item.value;
    };


    static getFareRule(sessionId) {
        let headerAndURL = this.getHeaderAndURL();
        let baseUrl =
            headerAndURL.flightApiHolidays +
            "/V1.0/api/BrandFare/FareCompare?SessionId=" +
            sessionId;
        return axios.get(baseUrl, {
            headers: headerAndURL.headers,
        });
    }
}
